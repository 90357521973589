import { useHistory, useLocation } from 'react-router';
import { useState, useEffect } from 'react';

import { GeneralCollapsibleNavigationItemProps } from '../../../../atomic/organism/GeneralCollapsibleNavigation/types';
import useWindowSize from '../../../../hooks/useGetWindowScreenSize';
import useLayoutStore from '../../../../stores/layoutStore';
import usePrimaryNavLogic from '../../../layout/PrimaryNavController/usePrimaryNavLogic';
import usePrimaryNavModel from '../../../layout/PrimaryNavController/usePrimaryNavModel';
import {
  KNOWLEDGE_CENTER,
  NOTEBOOK,
  NOTEBOOK_WITH_MYWORK_PRESELECTED,
  V2_HOME,
  V2_REWARDS,
} from '../../../../constants/routes';
import useNotebookStore from '../../../../stores/notebookStore';
import { useGetNotebookTodoCountQuery } from '../../../../queries/Notebook';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { GeneralListWithKnowledgeCenter } from './data';

const layoutSelector = (state: LayoutStoreState) => state.toggleLeftAsideOpen;

const useLeftDrawerGeneralListController = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { treatment: knowledgeCenter } = useFeatureSplit(
    SplitNames.KNOWLEDGE_CENTER,
  );

  let listItems = GeneralListWithKnowledgeCenter;

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const { totalTodayAndOverdueTasks, updateCount } = useNotebookStore();

  const history = useHistory();
  const { pathname } = useLocation();
  const toggleLeftAsideOpen = useLayoutStore(layoutSelector);

  const { isMobileWidth, isTabletWidth } = useWindowSize();

  const {
    models: {
      isCurrentUserAdmin,
      isQuickSetupDataLoading,
      showQuickSetupOption,
      hideRewardsLink,
    },
  } = usePrimaryNavModel();

  const { data: countData } = useGetNotebookTodoCountQuery();

  useEffect(() => {
    if (countData) {
      const { totalTodayAndOverdueTasks: count } = countData;
      updateCount(count);
    }
  }, [countData, updateCount]);

  const {
    operations: { trackLeftDrawerRewardsClick, trackLeftDrawerMainFeedClick },
  } = usePrimaryNavLogic(
    isQuickSetupDataLoading,
    isCurrentUserAdmin,
    showQuickSetupOption,
  );

  const handleOnItemClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const route = e.currentTarget.id;
    if (route === NOTEBOOK) history.push(NOTEBOOK_WITH_MYWORK_PRESELECTED);
    else history.push(route);

    if (isMobileWidth || isTabletWidth) {
      toggleLeftAsideOpen();
    }
    if (e.currentTarget.id === V2_HOME) {
      trackLeftDrawerMainFeedClick();
    }

    if (e.currentTarget.id === V2_REWARDS) {
      trackLeftDrawerRewardsClick();
    }
  };

  const getDisabledItems = (item: GeneralCollapsibleNavigationItemProps) => {
    switch (item.id) {
      case V2_REWARDS:
        return !hideRewardsLink;
      default:
        return true;
    }
  };

  listItems = listItems.map((item) => {
    if (item.id === NOTEBOOK) {
      return {
        ...item,
        count: totalTodayAndOverdueTasks,
        isActive: pathname.includes(item.id),
        isEnabled: getDisabledItems(item),
      };
    }
    return {
      ...item,
      isActive: pathname.includes(item.id),
      isEnabled: getDisabledItems(item),
    };
  });

  useEffect(() => {
    if (knowledgeCenter !== TreatmentTypes.ON) {
      listItems = listItems.filter((item) => item.id !== KNOWLEDGE_CENTER);
    }
  }, [knowledgeCenter]);

  const heading = 'General';
  return {
    model: {
      heading,
      isExpanded,
      generalListItems: listItems,
    },
    operations: {
      handleExpand,
      handleOnItemClick,
    },
  };
};

export default useLeftDrawerGeneralListController;
