// @ts-nocheck TODO: type issues need to be fixed in this file
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../constants';
import timezones from '../data/timezones/index.json';

const BEGIN_YEAR = 1972;
const START_YEAR = 1950;

export const getMonthFromDateObject = (dateObject) => {
  if (!dateObject.month && !dateObject.monthNumber) {
    return '';
  }
  if (dateObject.month) {
    return dateObject.month;
  }
  return moment.months(dateObject.monthNumber - 1);
};

export const getMonthsToShow = () => {
  return moment.months().map((mon) => {
    return {
      value: mon,
      label: mon,
    };
  });
};

const isFeb = (month) => moment().month(month).month() === 1;

export const getDaysForMonth = (month) => {
  const monthMoment = moment([1971, moment().month(month).month()]);
  const daysInMonth = isFeb(month)
    ? monthMoment.daysInMonth() + 1
    : monthMoment.daysInMonth();
  return Array.from({ length: daysInMonth }, (v, k) => ({
    value: k + 1,
  }));
};

export const getDateFromDateObject = (dateObject) => {
  if (!dateObject) {
    return null;
  }
  const { month, monthNumber, year = BEGIN_YEAR, date, day } = dateObject;

  if (day && month && year) {
    // this is to support the latest data structure[V3 API]
    return moment(`${year} ${month} ${day}`, 'YYYY MM DD');
  }
  if (month) {
    return moment(`${year} ${month} ${date ? date : day}`, 'YYYY MMMM DD');
  }
  return moment(`${year} ${monthNumber} ${date ? date : day}`, 'YYYY MM DD');
};

export const getDateObjectFromMomentDate = (momentDate) => {
  if (!momentDate) {
    return null;
  }
  const date = momentDate.date();
  const month = momentDate.format('MMMM');
  const monthNumber = Number(momentDate.format('M'));
  const year = Number(momentDate.format('YYYY'));
  return {
    date,
    month,
    monthNumber,
    year,
  };
};

export const validateMonthDayAndYear = (month, day, year = BEGIN_YEAR) => {
  const monthMoment = moment([year, moment().month(month).month(), day]);
  return monthMoment.isValid();
};

export const getHireYearOptions = () => {
  let END_YEAR = moment().year() + 1;
  const yearOptions = [];
  while (START_YEAR < END_YEAR) {
    yearOptions.push({ value: END_YEAR });
    END_YEAR -= 1;
  }
  return yearOptions;
};

export const getDatePhrase = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return `${moment.monthsShort()[month]} ${day}, ${year}`;
};

export const getStartDay = (date) => moment.utc(date).startOf('day').toDate();

export const getEndDay = (date) => moment.utc(date).endOf('day').toDate();

export const getMonthByNumber = (monthNumber) => {
  const monthsMap = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  return monthsMap[monthNumber];
};

export const getAbbreviatedMonthByNumber = (monthNumber) => {
  const abbreviatedMonthsMap = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  return abbreviatedMonthsMap[monthNumber];
};

export const getLocalTimeAtTimeZone = (ianaTimeZone, format = 'h:mma (z)') => {
  const localTime = moment();
  const convertedTime = moment.tz(localTime, ianaTimeZone);
  return convertedTime.format(format);
};

export const changeTimezone = (date, ianatz = DEFAULT_TIMEZONE) => {
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract
};

export const mapTimezoneValue = (timezoneValue) => {
  let filteredTimezone = timezones.filter(
    (timezone) => timezone.key === timezoneValue,
  );
  if (filteredTimezone.length === 0) {
    filteredTimezone = timezones.filter((timezone) =>
      timezone.otherUtc.includes(timezoneValue),
    );
  }
  if (filteredTimezone.length > 0) {
    return filteredTimezone[0].value;
  }
  return undefined;
};

export const detectTimezone = () => {
  const detectedValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const hasValue = mapTimezoneValue(detectedValue);
  if (hasValue !== undefined && hasValue.length > 0) {
    return detectedValue;
  }
  return null;
};

export const getCurrentTimeInTimeZone = (timeZone) => {
  if (timeZone) {
    const hasValue = mapTimezoneValue(timeZone);
    if (hasValue !== '' || hasValue !== undefined) {
      const now = new Date();
      const res = new Date(now.toLocaleString('en-US', { timeZone }));
      const time = res.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      const timezoneCode = moment.tz(timeZone).zoneAbbr();
      return `${time} (${timezoneCode})`;
    }
  }
  return null;
};

const convertTZ = (date: Date | string, tzString: string) => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    }),
  );
};

export const getCurrentDateForTimeZone = (timeZone: string): Date => {
  const timeZoneCurrentDate = convertTZ(new Date().toISOString(), timeZone);
  return timeZoneCurrentDate;
};

export const getDateForTimeZone = (
  date: Date | string,
  timeZone: string,
): Date => {
  return convertTZ(date, timeZone);
};
