/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TabProps } from '@material-ui/core/Tab';

import SVGIcon from '../../atoms/SVGIcon';

import ThemeV2 from '../../../componentsV2/theme';
import { StyledMoreButton, StyledTab, StyledTabs } from './styles';

type RoutesType = {
  key?: string;
  path?: string;
};

export type TabComponentProps = {
  tabItems: {
    tabHeader: TabProps & RoutesType;
    tabContent: React.ReactNode;
    isFiltered?: boolean;
  }[];
  splitEqually?: boolean;
  selectedTabIndex?: number;
  isNavigationEnabled?: boolean;
  variant?: 'scrollable' | 'fullWidth';
  onTabClick?: (tabName: string) => void;
  isTabPositionFixed?: boolean;
  className?: string;
};

export interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{
        height: 'calc(100vh-181px)',
      }}
    >
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && children}
      </div>
    </div>
  );
};

const MoreButton = (props: unknown) => {
  return (
    <StyledMoreButton {...props}>
      <SVGIcon icon="more" size="24px" color={ThemeV2.palette.gray8} />
    </StyledMoreButton>
  );
};

const TabComponent = (props: TabComponentProps) => {
  const {
    tabItems,
    splitEqually = false,
    selectedTabIndex = 0,
    isNavigationEnabled = false,
    variant = 'scrollable',
    onTabClick,
    isTabPositionFixed,
    className = '',
  } = props;

  const history = useHistory();
  const currentLocation = useLocation();

  const [selectedTabIndexValue, setSelectedTabIndexValue] =
    useState(selectedTabIndex);

  const [tabContentToRender, setTabContentToRender] =
    useState<ReactNode | null>(null);

  const onTabSelectionChange = (
    event: React.ChangeEvent<unknown>,
    newIndex: number,
  ) => {
    const clickedTab = tabItems.find((route, key) => key === newIndex);
    const tabName: any = clickedTab?.tabHeader.label || '';
    if (onTabClick) {
      onTabClick(
        tabName.props === undefined
          ? tabName.toLowerCase()
          : tabName.props.children.toString().toLowerCase(),
      );
    }

    if (isNavigationEnabled) {
      const selectedRoute = tabItems.find((route, key) => key === newIndex);
      if (selectedRoute?.tabHeader.path) {
        history.push({ pathname: selectedRoute?.tabHeader.path });
      }
    } else {
      setSelectedTabIndexValue(newIndex);
    }
  };

  useEffect(() => {
    if (isNavigationEnabled) {
      const currentRouteIndex = tabItems.findIndex((tab) =>
        tab.tabHeader && tab.tabHeader.path
          ? currentLocation.pathname === tab.tabHeader.path
          : '',
      );
      setSelectedTabIndexValue(currentRouteIndex || 0);
      const item = tabItems.find(
        (x) => x.tabHeader.path === currentLocation.pathname,
      );
      if (item) {
        setTabContentToRender(item.tabContent);
      }
    }
  }, [currentLocation, tabItems, isNavigationEnabled]);

  const hasIcon = tabItems.some((tab) => tab.tabHeader.icon !== undefined);

  return (
    <>
      <StyledTabs
        value={selectedTabIndexValue}
        onChange={onTabSelectionChange}
        textColor="primary"
        variant={variant}
        indicatorColor="primary"
        ScrollButtonComponent={MoreButton}
        data-testid="flows-tabs"
        $hasIcon={hasIcon}
        $splitEqually={splitEqually}
        scrollButtons="auto"
        $isTabPositionFixed={isTabPositionFixed}
        className={className}
      >
        {tabItems.map((tab, index) => {
          const isFiltered = tab.isFiltered === true;
          return (
            <StyledTab
              $hasIcon={hasIcon}
              $splitEqually={splitEqually}
              value={index}
              key={index}
              tabIndex={index}
              $isFiltered={isFiltered}
              {...tab.tabHeader}
            />
          );
        })}
      </StyledTabs>
      {tabItems.map((tabPanel, index) =>
        isNavigationEnabled ? (
          <TabPanel value={selectedTabIndexValue} index={index} key={index}>
            {tabContentToRender}
          </TabPanel>
        ) : (
          <TabPanel value={selectedTabIndexValue} index={index} key={index}>
            {tabPanel.tabContent}
          </TabPanel>
        ),
      )}
    </>
  );
};

export default TabComponent;
