import { ComponentStatus } from '../../../interfaces/component';
import { CurrentUser } from '../../../interfaces/currentUser';
import { DynamicBlockState, FlowVariants } from '../../../interfaces/Flow';
import { ParticipationFlowHeaderContent } from '../../molecules/FlowsParticipationHeader';

export type CommonParticipationTemplateProps = {
  member: CurrentUser;
  isFullScreen?: boolean;
  onCloseModal: () => void;
  flowId: string;
  headerStatus: ComponentStatus;
  flowHeaderContent: ParticipationFlowHeaderContent;
};

export enum ParticipationTemplateErrorTypes {
  ACCESS_DENIED = 'ACCESS_DENIED',
  FLOW_NOT_FOUND = 'FLOW_NOT_FOUND',
  INVALID_FLOW_ID = 'INVALID_FLOW_ID',
  FLOW_NOT_AVAILABLE = 'FLOW_NOT_AVAILABLE',
  INVALID_OCCURRENCE_ID = 'INVALID_OCCURRENCE_ID',
  ACTIVE_OCCURRENCE_ID_MISMATCH = 'ACTIVE_OCCURRENCE_ID_MISMATCH',
  ACTIVE_OCCURRENCE_ENDED = 'ACTIVE_OCCURRENCE_ENDED',
  OCCURRENCE_ALREADY_RESPONDED = 'OCCURRENCE_ALREADY_RESPONDED',
  NO_ACTIVE_OCCURRENCE_FOUND = 'NO_ACTIVE_OCCURRENCE_FOUND',
  UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS = 'UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS',
  FLOW_UPDATED = 'FLOW_UPDATED',
  INVALID_BLOCK_PARAMETERS = 'INVALID_BLOCK_PARAMETERS',
}

export enum ExternalParticipationTemplateErrorTypes {
  FLOW_NOT_FOUND = 'FLOW_NOT_FOUND',
  INVALID_FLOW_ID = 'INVALID_FLOW_ID',
  FLOW_NOT_AVAILABLE = 'FLOW_NOT_AVAILABLE',
  ACCESS_DENIED = 'ACCESS_DENIED',
  NO_ACTIVE_OCCURRENCE_FOUND = 'NO_ACTIVE_OCCURRENCE_FOUND',
  FLOW_UPDATED = 'FLOW_UPDATED',
  ACTIVE_OCCURRENCE_ENDED = 'ACTIVE_OCCURRENCE_ENDED',
}

export interface ParticipationTemplateErrorProps
  extends CommonParticipationTemplateProps {
  templateError: ParticipationTemplateErrorTypes;
  onAnswerWithShortcutButtonClick?: () => void;
  setCustomError?: React.Dispatch<
    React.SetStateAction<ParticipationTemplateErrorTypes | undefined>
  >;
  returnOnlyContent?: boolean;
}

export interface ExternalParticipationTemplateErrorProps {
  externalTemplateError: ExternalParticipationTemplateErrorTypes;
}

export interface ParticipationTemplateProps
  extends CommonParticipationTemplateProps {
  footerButtons: JSX.Element[];
  children: React.ReactNode;
  currentStep: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isNextButtonDisabled: boolean;
  isPreviousButtonDisabled: boolean;
  onStepChange: (step: number) => void;
  dynamicBlockData: DynamicBlockState[];
  singleSlideContainerRef: React.RefObject<HTMLDivElement>;
  flowVariant: FlowVariants;
  onNeedHelpButtonClick?: () => void;
  onPromptOpen?: () => void;
  onPromptClose?: () => void;
  previewContent?: { icon: string; title: string; isTemplate: boolean };
  bannerProps?: {
    bannerIcon: string;
    bannerText: string;
  };
  creator?: {
    name: string;
    logo: string;
  };
}
