export const zIndexButton = 1;
export const zIndexCard = 1;
export const zIndexTabsHeader = 2;
export const zIndexNav = 4;
export const zIndexPrimaryNav = 3;
export const zIndexActionBarModal = 4;
export const zIndexFooter = 2;
export const zIndexKioskFooter = 9999;
export const zIndexHeader = 1299;
export const zIndexPopup = 1300;
export const zIndexEmojiPickerLabel = 2;
