import { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CookieHandler from 'react-cookies';
import get from 'lodash/get';
import { parse } from 'qs';

import RoutesList from '../../aV2/routes/routesList';
import useFetch from '../../hooks/useFetch';
import {
  userSessionCreatedAction,
  handleUserSignin,
} from '../../modules/Session';
import { AUTHORIZE_SSO, SIGN_IN_SSO } from '../../hooks/useFetch/endpoints';
import { COOKIE_CONSTANTS } from '../../Utils/constants';
import { APP_URL } from '../../config';
import { ApiResponseError } from '../../interfaces/identityManager/common';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';

const useVerifyIMCode = (sso: string) => {
  const REDIRECT_URI = `${APP_URL}${RoutesList.IM_AUTHORIZE_SSO(sso)}`;
  const { search } = useLocation();
  const { push } = useHistory();
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [verifyError, setVerifyError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const isAdminTreatmentOn = adminRefactorTreatment === TreatmentTypes.ON;

  const handleError = useCallback(
    ({ message }: ApiResponseError) => {
      setIsVerifyingCode(false);
      setVerifyError(message);
      if (message) {
        // eslint-disable-next-line max-len
        if (isAdminTreatmentOn) {
          window.location.href = `${RoutesList.ADMIN_USER_MANAGE_IDENTITY}/${sso}?error=${message}&sso=${sso}`;
        } else {
          push(
            // Adding sso to query param as we are handling both IM and Sign in using
            // same URL for ADP. When it error outs, on Sign in flow, it will get
            // redirected to /signin with the error query param. So adding sso
            // query param, we can identify from which sso it was redirected.
            `${RoutesList.ADMIN_USER_MANAGE_IDENTITY_LEGACY}/${sso}?error=${message}&sso=${sso}`,
          );
        }
      }
    },
    [isAdminTreatmentOn, push, sso],
  );

  const authorizeSuccess = () => {
    setIsVerifyingCode(false);
    CookieHandler.remove(COOKIE_CONSTANTS.FROM_IDENTITY_MANAGER_AUTHORIZE, {
      path: '/',
    });
    if (isAdminTreatmentOn) {
      window.location.href = `${RoutesList.ADMIN_USER_MANAGE_IDENTITY}/${sso}`;
    } else {
      push(`${RoutesList.ADMIN_USER_MANAGE_IDENTITY_LEGACY}/${sso}`);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO: Convert useFetch to Typescript
  const signInSuccess = (response) => {
    setIsVerifyingCode(false);
    const data = get(response, ['data'], null);
    if (data) {
      dispatch(userSessionCreatedAction(data.user, data.jwtToken));
      handleUserSignin(data.user);
    }
  };

  const { makeRequest: makeAuthorizeRequest } = useFetch({
    apiEndpoint: AUTHORIZE_SSO,
    onSuccess: authorizeSuccess,
    onFailure: handleError,
  });

  const { makeRequest: makeSigninRequest } = useFetch({
    apiEndpoint: SIGN_IN_SSO,
    onSuccess: signInSuccess,
    onFailure: handleError,
  });

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isCodePresent = parsedParams && parsedParams.code;
    if (isCodePresent) {
      const { code } = parsedParams;
      setIsVerifyingCode(true);
      const isFromAuthorize = CookieHandler.load(
        COOKIE_CONSTANTS.FROM_IDENTITY_MANAGER_AUTHORIZE,
      );
      if (isFromAuthorize === 'true') {
        makeAuthorizeRequest({
          data: { code, redirectUri: REDIRECT_URI },
          substitutions: { sso },
        });
      } else {
        makeSigninRequest({
          data: { code, redirectUri: REDIRECT_URI },
          substitutions: { sso },
        });
      }
    }
    const hasError = parsedParams && parsedParams.error;
    if (hasError) {
      push(
        `${RoutesList.ADMIN_USER_MANAGE_IDENTITY_LEGACY}/${sso}?error=${parsedParams.error}`,
      );
    }
  }, [
    sso,
    search,
    REDIRECT_URI,
    makeAuthorizeRequest,
    makeSigninRequest,
    push,
  ]);

  return {
    isVerifyingCode,
    verifyError,
  };
};
export default useVerifyIMCode;
