import { useCallback, useState } from 'react';

import { V3_POST_PAGE } from '../../../constants/routes';

import { useGetMainFeedAttachmentsQuery } from '../../../queries/KnowledgeCenter';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { useFetchFlowActive } from '../../../queries/Flows/Dashboard';
import { FeedAttachmentItemFromAPI } from '../../../interfaces/Feed';
import { downloadFeedFile } from '../../../Utils/flows';
import { useFetchFlowFileQuery } from '../../../queries/Flows/Feed';
import copy from 'copy-to-clipboard';

const useKnowledgeCenterController = () => {
  const [selectedFile, setSelectedFile] = useState<
    FeedAttachmentItemFromAPI | undefined
  >(undefined);
  const { data: activeFlowsData } = useFetchFlowActive();
  const activeFlows = activeFlowsData?.data;

  const {
    data,
    refetch,
    isLoading: isMainFeedLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetMainFeedAttachmentsQuery();

  const handleFetchNextPage = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleSetSelectedFile = useCallback(
    (value: FeedAttachmentItemFromAPI | undefined) => setSelectedFile(value),
    [setSelectedFile],
  );

  const { data: profileData, isLoading: isProfileDataLoading } =
    useProfileInfoFetchQuery();

  const copyPostLink = useCallback((flowId, responseId) => {
    // slice removes the ending /
    const link = `${V3_POST_PAGE}`.replace(
      ':postType',
      `flows?flowId=${flowId}&responseId=${responseId}`,
    );
    copy(link);
  }, []);

  const onDownloadFileClick = useCallback(async (fileDownload) => {
    await downloadFeedFile({
      blockId: fileDownload?.response.blockId,
      responseId: fileDownload?.response.responseId,
      fileName: fileDownload?.fileInfo.name,
      flowId: fileDownload?.response.flowId,
    });
  }, []);

  const { data: fileToPreview } = useFetchFlowFileQuery(
    {
      responseId: selectedFile ? selectedFile.response.responseId : '',
      blockId: selectedFile ? selectedFile.response.blockId : '',
      fileName: selectedFile ? selectedFile.fileInfo.name : '',
      flowId: selectedFile ? selectedFile.response.flowId : '',
    },
    selectedFile !== undefined,
  );

  const isLoading = isMainFeedLoading || isProfileDataLoading;

  return {
    isLoading,
    activeFlows,
    hasNextPage,
    profileData,
    data,
    isFetchingNextPage,
    selectedFile,
    fileToPreview,
    refetch,
    handleFetchNextPage,
    handleSetSelectedFile,
    onDownloadFileClick,
    copyPostLink,
  };
};

export default useKnowledgeCenterController;
