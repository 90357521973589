import React, { useCallback, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import { FlowVariants } from '../../../interfaces/Flow';
import FlowsParticipationFooter from '../../molecules/FlowsParticipationFooter';
import FlowsParticipationModal from '../../molecules/FlowsParticipationModal';
import Body from '../../atoms/Body';
import searching from '../../../img/searching-girl.svg';
import publicGroup from '../../../img/public-group.svg';
import onlineConversation from '../../../img/online-conversation.svg';
import FlowStepper from '../../molecules/FlowStepper';
import giveRecognition from '../../../img/give-recognition.svg';
import SimplePromptModal from '../../molecules/SimplePromptModal';
import { LEAVE_DRAFT_MODAL_LANGUAGES } from '../../../languages/en/giveRecognitionForm';
import useToggle from '../../../hooks/useToggle';
import { ComponentStatus } from '../../../interfaces/component';
import {
  Banner,
  Container,
  Content,
  ErrorCTAButton,
  ErrorImage,
  FullScreen,
  PreviewInfoContainer,
  SingleSlideContainer,
  Slide,
  SlideContainer,
  StyledBannerIcon,
  StyledFlexDiv,
  StyledSVG,
  StyledTextInput,
  TemplateLoader,
  variants,
} from './styles';
import ParticipationFlowHeader from '../../molecules/FlowsParticipationHeader';
import { Flex } from '../../../Utils/styles/display';
import Heading from '../../atoms/Heading';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import useModalsStore from '../../../stores/modalsStore';
import {
  ALREADY_ANSWERED_OCCURRENCE,
  ANSWER_ANYTIME_TEXT,
  ANSWER_NEW_OCCURRENCE,
  ANSWER_NOW,
  ANSWER_WITH_SHORTCUT,
  ANSWER_WITH_SHORTCUT_TEXT,
  CANT_FIND_FLOW,
  EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS,
  HEAD_TO_MAIN_FEED,
  NEW_OCCURRENCE_AVAILABLE_DESCRIPTION,
  NEW_VERSION_AVAILABLE,
  NEW_VERSION_AVAILABLE_DESCRIPTION,
  NO_CURRENT_OCCURRENCE,
  OCCURRENCE_HAS_ENDED,
  RELOAD_FLOW,
  STILL_ANSWER_WITH_SHORTCUT,
  WAIT_TILL_NEXT_OCCURRENCE,
  YOU_ARE_PREVIEWING,
} from '../../../languages/en/flows/participation';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import EmoticonWithText from '../../atoms/EmoticonWithText';
import ThemeV2 from '../../../componentsV2/theme';
import { TEMPLATE, THE } from '../../../languages/en/singleWords';
import Button from '../../atoms/Button';
import {
  CommonParticipationTemplateProps,
  ExternalParticipationTemplateErrorProps,
  ExternalParticipationTemplateErrorTypes,
  ParticipationTemplateErrorProps,
  ParticipationTemplateErrorTypes,
  ParticipationTemplateProps,
} from './types';
import { isValidEmail } from '../../../Utils/validation';
import { AxiosResponse } from 'axios';
import { ValidateEmailRequestForOnboardingSuccessResponse } from '../../../queries/Onboarding/interfaces';
import { EMPLOYEE_VERIFY_LINK, SIGN_IN } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useValidateEmailRequestForOnboarding } from '../../../queries/Onboarding';

export const LoadedParticipationTemplate = (
  props: ParticipationTemplateProps,
) => {
  const shouldDisplayHelpButton = true;
  const {
    footerButtons,
    children,
    currentStep,
    goToNextStep,
    goToPreviousStep,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    member,
    onCloseModal,
    onStepChange,
    dynamicBlockData,
    singleSlideContainerRef,
    flowHeaderContent,
    headerStatus,
    onNeedHelpButtonClick,
    onPromptOpen = () => {},
    onPromptClose = () => {},
    flowVariant,
    previewContent,
    bannerProps,
    isFullScreen = false,
    creator,
  }: ParticipationTemplateProps = props;

  const {
    models: { toggleValue: showClosePrompt },
    operations: {
      setToggleToFalse: setShowPromptToFalse,
      setToggleToTrue: setShowPromptToTrue,
    },
  } = useToggle();

  const showPrompt = () => {
    setShowPromptToTrue();
    if (onPromptOpen) {
      onPromptOpen();
    }
  };

  const hidePrompt = () => {
    setShowPromptToFalse();
    if (onPromptClose) {
      onPromptClose();
    }
  };

  const isPreviewFlow = flowVariant === FlowVariants.PREVIEW_FLOW;
  const isExternalFlow = flowVariant === FlowVariants.EXTERNAL_FLOW;

  const hideBannerForExternalFlow = isExternalFlow && currentStep === 0;

  if (isFullScreen) {
    return (
      <FullScreen>
        <SimplePromptModal
          isOpen={showClosePrompt}
          onModalClose={hidePrompt}
          onSuccess={onCloseModal}
          primaryButtonStatus="warning"
          {...LEAVE_DRAFT_MODAL_LANGUAGES}
        />
        <Container>
          <ParticipationFlowHeader
            member={member}
            onCloseModal={isPreviewFlow ? onCloseModal : showPrompt}
            flowHeaderContent={flowHeaderContent}
            status={headerStatus}
            isAnonymous={!!bannerProps}
            showCloseModalOption={!isExternalFlow}
          />
          <FlowStepper
            currentStep={currentStep}
            onStepChange={onStepChange}
            stepStates={dynamicBlockData}
            status={ComponentStatus.LOADED}
            isPreviewFlow={isPreviewFlow}
            isExternalFlow={isExternalFlow}
          />
          <Content>
            {hideBannerForExternalFlow
              ? null
              : bannerProps && (
                  <Banner>
                    <StyledBannerIcon icon={bannerProps.bannerIcon} />
                    <Body variant="body1Medium">{bannerProps.bannerText}</Body>
                  </Banner>
                )}
            <SlideContainer>
              <SingleSlideContainer ref={singleSlideContainerRef}>
                <AnimatePresence initial={false}>
                  <Slide
                    key={currentStep}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                  >
                    {isPreviewFlow && previewContent && (
                      <PreviewInfoContainer>
                        <StyledFlexDiv isExternalFlow={isExternalFlow}>
                          <StyledSVG
                            icon="eye"
                            size="20px"
                            color={ThemeV2.palette.geekBlue6}
                          />
                          <Body variant="body1Medium" color="geekBlue6">
                            {YOU_ARE_PREVIEWING}{' '}
                            {previewContent.isTemplate && `${THE} `}
                            {previewContent.icon ? (
                              <>
                                <EmoticonWithText
                                  emoticon={mapHexCodeToEmoticon(
                                    previewContent.icon,
                                  )}
                                  text={previewContent.title}
                                />{' '}
                              </>
                            ) : (
                              previewContent.title
                            )}
                            {previewContent.isTemplate && TEMPLATE}
                          </Body>
                        </StyledFlexDiv>
                      </PreviewInfoContainer>
                    )}
                    {children}
                  </Slide>
                </AnimatePresence>
              </SingleSlideContainer>
            </SlideContainer>
          </Content>
          <FlowsParticipationFooter
            status={ComponentStatus.LOADED}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            onPreviousButtonClick={goToPreviousStep}
            onNextButtonClick={goToNextStep}
            shouldDisplayHelpButton={
              isExternalFlow ? false : shouldDisplayHelpButton
            }
            onNeedHelpButtonClick={onNeedHelpButtonClick}
            footerButtons={footerButtons}
            creator={creator}
            isExternalFlow={isExternalFlow}
          />
        </Container>
      </FullScreen>
    );
  }

  return (
    <FlowsParticipationModal handleClose={showPrompt} isOpen>
      <SimplePromptModal
        isOpen={showClosePrompt}
        onModalClose={hidePrompt}
        onSuccess={onCloseModal}
        primaryButtonStatus="warning"
        {...LEAVE_DRAFT_MODAL_LANGUAGES}
      />
      <Container>
        <ParticipationFlowHeader
          member={member}
          onCloseModal={isPreviewFlow ? onCloseModal : showPrompt}
          flowHeaderContent={flowHeaderContent}
          status={headerStatus}
          isAnonymous={!!bannerProps}
        />
        <FlowStepper
          currentStep={currentStep}
          onStepChange={onStepChange}
          stepStates={dynamicBlockData}
          status={ComponentStatus.LOADED}
          isPreviewFlow={isPreviewFlow}
        />
        <Content>
          {bannerProps && (
            <Banner>
              <StyledBannerIcon icon={bannerProps.bannerIcon} />
              <Body variant="body1Medium">{bannerProps.bannerText}</Body>
            </Banner>
          )}
          <SlideContainer>
            <SingleSlideContainer ref={singleSlideContainerRef}>
              <AnimatePresence initial={false}>
                <Slide
                  key={currentStep}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                >
                  {isPreviewFlow && previewContent && (
                    <PreviewInfoContainer>
                      <StyledFlexDiv>
                        <StyledSVG
                          icon="eye"
                          size="20px"
                          color={ThemeV2.palette.geekBlue6}
                        />
                        <Body variant="body1Medium" color="geekBlue6">
                          {YOU_ARE_PREVIEWING}{' '}
                          {previewContent.isTemplate && `${THE} `}
                          {previewContent.icon ? (
                            <>
                              <EmoticonWithText
                                emoticon={mapHexCodeToEmoticon(
                                  previewContent.icon,
                                )}
                                text={previewContent.title}
                              />{' '}
                            </>
                          ) : (
                            previewContent.title
                          )}
                          {previewContent.isTemplate && TEMPLATE}
                        </Body>
                      </StyledFlexDiv>
                    </PreviewInfoContainer>
                  )}
                  {children}
                </Slide>
              </AnimatePresence>
            </SingleSlideContainer>
          </SlideContainer>
        </Content>
        <FlowsParticipationFooter
          status={ComponentStatus.LOADED}
          isNextButtonDisabled={isNextButtonDisabled}
          isPreviousButtonDisabled={isPreviousButtonDisabled}
          onPreviousButtonClick={goToPreviousStep}
          onNextButtonClick={goToNextStep}
          shouldDisplayHelpButton={shouldDisplayHelpButton}
          onNeedHelpButtonClick={onNeedHelpButtonClick}
          footerButtons={footerButtons}
        />
      </Container>
    </FlowsParticipationModal>
  );
};

export const LoadingParticipationTemplate = ({
  member,
  onCloseModal,
  flowHeaderContent,
  headerStatus,
  isFullScreen,
}: CommonParticipationTemplateProps) => (
  <FlowsParticipationModal
    isOpen
    handleClose={onCloseModal}
    isFullScreen={isFullScreen}
  >
    <Container>
      <ParticipationFlowHeader
        member={member}
        onCloseModal={onCloseModal}
        flowHeaderContent={flowHeaderContent}
        status={headerStatus}
      />
      <FlowStepper status={ComponentStatus.LOADING} />
      <Content>
        <SlideContainer>
          <TemplateLoader />
        </SlideContainer>
      </Content>
      <FlowsParticipationFooter status={ComponentStatus.LOADING} />
    </Container>
  </FlowsParticipationModal>
);

export const ParticipationTemplateError = ({
  member,
  onCloseModal,
  flowId,
  templateError,
  onAnswerWithShortcutButtonClick,
  flowHeaderContent,
  headerStatus,
  setCustomError,
  returnOnlyContent = false,
}: ParticipationTemplateErrorProps) => {
  const { setParticipationFlow } = useModalsStore();
  const { data: flowDetails } = useFetchFlowDetailsQuery(
    flowId,
    '',
    templateError !== 'INVALID_FLOW_ID' && templateError !== 'FLOW_NOT_FOUND',
  );
  const [headingText, descriptionText, ErrorCTAElement, errorImageSrc] =
    useMemo(() => {
      let occurrenceId: string | undefined;
      let isShortcut = false;
      if (flowDetails) {
        occurrenceId =
          flowDetails.data.occurrence.activeOccurrence?.occurrenceId;
        isShortcut = flowDetails.data.isShortcut;
      }

      switch (templateError) {
        case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ID_MISMATCH: {
          const onClick = () => {
            setParticipationFlow({ occurrenceId, participationFlowId: flowId });
          };
          return [
            OCCURRENCE_HAS_ENDED,
            NEW_OCCURRENCE_AVAILABLE_DESCRIPTION,
            flowDetails ? (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_NEW_OCCURRENCE}
              </ErrorCTAButton>
            ) : null,
            publicGroup,
          ];
        }
        case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED: {
          let imageSrc = publicGroup;
          let ButtonElement: JSX.Element | null = null;
          let text = WAIT_TILL_NEXT_OCCURRENCE;
          if (isShortcut) {
            const onClick = () =>
              setParticipationFlow({ participationFlowId: flowId });
            imageSrc = onlineConversation;
            ButtonElement = (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_WITH_SHORTCUT}
              </ErrorCTAButton>
            );
            text = ANSWER_WITH_SHORTCUT_TEXT;
          }
          return [OCCURRENCE_HAS_ENDED, text, ButtonElement, imageSrc];
        }
        case ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED: {
          let imageSrc = publicGroup;
          let ButtonElement: JSX.Element | null = null;
          let text = NO_CURRENT_OCCURRENCE;
          if (isShortcut) {
            imageSrc = onlineConversation;
            const onClick = () => {
              if (onAnswerWithShortcutButtonClick) {
                onAnswerWithShortcutButtonClick();
              }
            };

            // setParticipationFlow({ participationFlowId: flowId });
            ButtonElement = (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_WITH_SHORTCUT}
              </ErrorCTAButton>
            );
            text = STILL_ANSWER_WITH_SHORTCUT;
          }
          return [ALREADY_ANSWERED_OCCURRENCE, text, ButtonElement, imageSrc];
        }

        case ParticipationTemplateErrorTypes.FLOW_UPDATED: {
          const imageSrc = giveRecognition;
          const onClick = () => {};

          const ButtonElement = (
            <ErrorCTAButton key="error" onClick={onClick}>
              {RELOAD_FLOW}
            </ErrorCTAButton>
          );
          return [
            NEW_VERSION_AVAILABLE,
            NEW_VERSION_AVAILABLE_DESCRIPTION,
            ButtonElement,
            imageSrc,
          ];
        }

        case ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND: {
          let imageSrc = publicGroup;
          let ButtonElement: JSX.Element | null = null;
          let text = WAIT_TILL_NEXT_OCCURRENCE;
          if (isShortcut) {
            const onClick = () => {
              if (setCustomError) {
                setCustomError(undefined);
              }
              setParticipationFlow({ participationFlowId: flowId });
            };

            imageSrc = onlineConversation;
            ButtonElement = (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_NOW}
              </ErrorCTAButton>
            );
            text = ANSWER_ANYTIME_TEXT;
          }
          return [OCCURRENCE_HAS_ENDED, text, ButtonElement, imageSrc];
        }

        case ParticipationTemplateErrorTypes.INVALID_FLOW_ID:
        case ParticipationTemplateErrorTypes.FLOW_NOT_FOUND:
        case ParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
        case ParticipationTemplateErrorTypes.INVALID_OCCURRENCE_ID:
        case ParticipationTemplateErrorTypes.UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS:
        default: {
          return [CANT_FIND_FLOW, HEAD_TO_MAIN_FEED, null, searching];
        }
      }
    }, [
      flowDetails,
      flowId,
      onAnswerWithShortcutButtonClick,
      setCustomError,
      setParticipationFlow,
      templateError,
    ]);

  const content = useMemo(() => {
    return (
      <Content>
        <SlideContainer>
          <Flex
            alignItems="center"
            justifyContent="center"
            height="100%"
            flexDirection="column"
          >
            <ErrorImage alt="error-image" src={errorImageSrc} />
            <Flex flexDirection="column" width="380px">
              <Heading variant="h6">{headingText}</Heading>
              <Body variant="body1" textAlign="center">
                {descriptionText}
              </Body>
              {ErrorCTAElement}
            </Flex>
          </Flex>
        </SlideContainer>
      </Content>
    );
  }, [ErrorCTAElement, descriptionText, errorImageSrc, headingText]);
  if (returnOnlyContent) return content;
  return (
    <FlowsParticipationModal isOpen handleClose={onCloseModal}>
      <Container>
        <ParticipationFlowHeader
          flowHeaderContent={flowHeaderContent}
          status={headerStatus}
          member={member}
          onCloseModal={onCloseModal}
        />
        <FlowStepper status={ComponentStatus.LOADING} />
        {content}
        <FlowsParticipationFooter
          status={ComponentStatus.ERROR}
          onClick={onCloseModal}
        />
      </Container>
    </FlowsParticipationModal>
  );
};

export const ExternalParticipationTemplateError = ({
  externalTemplateError,
}: ExternalParticipationTemplateErrorProps) => {
  const { push } = useHistory();
  const { mutate } = useValidateEmailRequestForOnboarding();
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const handleExploreForFreeClick = useCallback(() => {
    if (email) {
      setHasError(!isValidEmail(email));

      if (!isValidEmail(email)) {
        return;
      }

      const payload = { email: email };

      mutate(payload, {
        onSuccess: (
          res: AxiosResponse<ValidateEmailRequestForOnboardingSuccessResponse>,
        ) => {
          push(`${EMPLOYEE_VERIFY_LINK}/${res.data.data.userId}`);
        },
        onError: () => {
          push(SIGN_IN);
        },
      });
    }
  }, [email]);
  const [headingText, descriptionText, ErrorCTAElement, errorImageSrc] =
    useMemo(() => {
      if (
        externalTemplateError ===
        ExternalParticipationTemplateErrorTypes.FLOW_UPDATED
      ) {
        const ButtonElement = (
          <ErrorCTAButton key="error" onClick={() => {}} icon="retry">
            {RELOAD_FLOW}
          </ErrorCTAButton>
        );
        return [
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.NEW_VERSION_AVAILABLE_HEADING,
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.NEW_VERSION_AVAILABLE_SUB_HEADING,
          ButtonElement,
          giveRecognition,
        ];
      }

      const TextInputAndButtonElement = (
        <Flex
          margin="20px 0 0 0"
          width="100%"
          alignItems="baseline"
          justifyContent="center"
          height="72px"
        >
          <StyledTextInput
            value={email}
            onChange={(e) => {
              setHasError(false);
              setEmail(e.target.value);
            }}
            placeholder={
              EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.PLACEHOLDER_TEXT
            }
            hasError={hasError}
            helperText={
              hasError
                ? EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ERROR_TEXT
                : ''
            }
            label={
              hasError
                ? EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.PLACEHOLDER_TEXT
                : ''
            }
          />
          <Button key="error" onClick={handleExploreForFreeClick}>
            {EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.EXPLORE_FOR_FREE}
          </Button>
        </Flex>
      );
      if (
        externalTemplateError ===
          ExternalParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED ||
        externalTemplateError ===
          ExternalParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND
      ) {
        const imageSrc = publicGroup;

        return [
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ACTIVE_OCCURRENCE_ENDED_HEADING,
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ACTIVE_OCCURRENCE_ENDED_SUB_HEADING,
          TextInputAndButtonElement,
          imageSrc,
        ];
      }

      return [
        EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.FLOW_NOT_FOUND_HEADING,
        EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.FLOW_NOT_FOUND_SUB_HEADING,
        TextInputAndButtonElement,
        searching,
      ];
    }, [email, externalTemplateError, handleExploreForFreeClick, hasError]);
  return (
    <Content>
      <SlideContainer>
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexDirection="column"
        >
          <ErrorImage alt="error-image" src={errorImageSrc} />
          <Flex flexDirection="column" width="380px" justifyContent="center">
            <Heading variant="h6" textAlign="center">
              {headingText}
            </Heading>
            <Body variant="body1" textAlign="center">
              {descriptionText}
            </Body>
            {ErrorCTAElement}
          </Flex>
        </Flex>
      </SlideContainer>
    </Content>
  );
};
