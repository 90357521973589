// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import _ from 'lodash';
import { API_URL_WITH_V2, API_URL_WITH_V3, SOURCE } from '../../config';
import { adminProcessError } from '../Admin';
import Logger from '../../Utils/Logger';
import { AuthHeaders } from '../shared/HeaderToken';
import { showSuccessMessage, showInfoMessage } from '../../Utils/flashHandler';
import { addSourceToURL } from '../../Utils/api';
// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  INVITE_USERS: `${API_URL_WITH_V2}/employer/employee/invite`,
  INVITE_USERS_V3: `${API_URL_WITH_V3}/assembly/members/invite`,
  INVITE_USERS_EDIT_V3: `${API_URL_WITH_V3}/assembly/members/invite/edit`,
  INVITE_USERS_CREATE_V3: `${API_URL_WITH_V3}/assembly/members/invite/create`,
  INVITE_USERS_EMAILS: `${API_URL_WITH_V2}/employer/employee/invite/emails`,
  APPROVE_INVITED_USERS: `${API_URL_WITH_V3}/assembly/members/invite/approve`,
  DELETE_INVITED_USERS: `${API_URL_WITH_V3}/assembly/members/invite/reject`,
};

// ------------------------------------
// Constants
// ------------------------------------

export const ADD_INVITED_USERS_SUCCESS = 'add_invited_users_success';
export const GET_INVITED_USERS_SUCCESS = 'get_invited_users_success';
export const UPDATE_INVITED_USER_SUCCESS = 'update_invited_users_success';
export const UPDATE_INVITED_USERS_SUCCESS = 'update_bulk_invited_users_success';
export const DELETE_INVITED_USER_SUCCESS = 'delete_invited_users_success';
export const USER_REQUEST_FAILED = 'user_request_failed';

// ------------------------------------
// Actions
// ------------------------------------

const addInvitedUsersSuccess = (users) => ({
  type: ADD_INVITED_USERS_SUCCESS,
  users,
});

const getInvitedUsersSuccess = (users) => ({
  type: GET_INVITED_USERS_SUCCESS,
  users,
});
const updateInvitedUserSuccess = (user) => ({
  type: UPDATE_INVITED_USER_SUCCESS,
  user,
});

const updateInvitedUsersSuccess = (invites) => ({
  type: UPDATE_INVITED_USERS_SUCCESS,
  invites,
});

const deleteInvitedUserSuccess = (userId) => ({
  type: DELETE_INVITED_USER_SUCCESS,
  userId,
});
const requestFailed = (errorMessage) => ({
  type: USER_REQUEST_FAILED,
  error: errorMessage,
});

export const actions = {
  getInvitedUsersSuccess,
  updateInvitedUserSuccess,
  addInvitedUsersSuccess,
  deleteInvitedUserSuccess,
  updateInvitedUsersSuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getInviteUsersAction = (cb) => {
  Logger('getInviteUsersAction', false);
  return async (dispatch) => {
    try {
      const res = await axios.get(apiEndpoints.INVITE_USERS_V3, AuthHeaders());
      const result = res.data;

      Logger('getInvitedUsersSuccess');
      Logger(result);
      if (result) {
        cb(true);
        dispatch(actions.getInvitedUsersSuccess(result.data));
      } else {
        cb(true);
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const uploadInviteUserAction = ({ data, onSuccess }) => {
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Inviting user(s)...'));
      const res = await axios.post(
        addSourceToURL(apiEndpoints.INVITE_USERS_CREATE_V3, SOURCE),
        { data },
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      if (result && result.addedMembers.length > 0) {
        dispatch(showSuccessMessage('User(s) has been invited'));
        if (onSuccess) {
          //dispatch(actions.addInvitedUsersSuccess(result.addedMembers));
          onSuccess(result.addedMembers);
        }
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const updateInvitedUsersAction = (props) => {
  const { data, onSuccess } = props;
  Logger(props);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Updating users...'));
      const res = await axios.post(
        apiEndpoints.INVITE_USERS_EDIT_V3, //eslint-disable-line
        {
          data,
        },
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      if (result && result.errors.length === 0) {
        dispatch(showSuccessMessage('Users has been updated'));
        onSuccess(result.updatedData);
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const approveQueuedInvitedUsersAction = (props) => {
  const { userIds, onSuccess } = props;

  let submitJob = false;
  if (userIds.length > 10) {
    submitJob = true;
  }
  const data = {
    userInviteIds: userIds,
    submitJob,
  };
  Logger(props);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Approving invites...'));
      const res = await axios.post(
        `${apiEndpoints.APPROVE_INVITED_USERS}`, //eslint-disable-line
        data,
        AuthHeaders(),
      );
      const result = res.data.status;
      Logger(res);
      if (result === 'QUEUED') {
        dispatch(showSuccessMessage('Invite(s) will get approved soon!'));
      } else {
        onSuccess();
        dispatch(showSuccessMessage('Invite(s) has been approved!'));
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const reactivateDeactivatedUsersAction = (props) => {
  const { userIds, onSuccess } = props;

  let submitJob = false;
  if (userIds.length > 10) {
    submitJob = true;
  }
  const data = {
    userInviteIds: userIds,
    submitJob,
  };
  Logger(props);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Reactivate users...'));
      const res = await axios.post(
        `${apiEndpoints.APPROVE_INVITED_USERS}`,
        data,
        AuthHeaders(),
      );
      const result = res.data.status;
      Logger(res);
      if (result === 'QUEUED') {
        dispatch(showSuccessMessage('Members will reactivated soon!'));
      } else {
        onSuccess();
        dispatch(showSuccessMessage('Reactivated members'));
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const deleteQueuedInvitedUsersAction = (props) => {
  const { memberIds, onSuccess } = props;
  const data = {
    memberIds,
  };
  Logger(props);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Deleting invites...'));
      const res = await axios.post(
        `${apiEndpoints.DELETE_INVITED_USERS}`,
        data,
        AuthHeaders(),
      );
      const result = res.data.status;
      Logger(result);
      onSuccess();
      dispatch(showSuccessMessage('User invite(s) has been deleted!'));
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const remindInviteUserAction = (props) => {
  const user = props;
  Logger(`remindInviteUserAction ${user}`, true);
  Logger(user, true);
  const name = `${user.profile.firstName} ${user.profile.lastName}`;

  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${apiEndpoints.INVITE_USERS_V3}/${user.memberId}/sendreminder`,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      dispatch(showSuccessMessage(`We have sent an email to ${name}`));
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const approveInviteUserAction = (props, cb, overideCb) => {
  const userId = props;
  Logger(`approveInviteUserAction ${userId}`, true);
  // const name = `${user.firstName} ${user.lastName}`;

  return async (dispatch) => {
    try {
      const res = await axios.post(
        apiEndpoints.APPROVE_INVITED_USERS,
        {
          userInviteIds: [userId],
          submitJob: false,
        },
        AuthHeaders(),
      );
      const result = res.data;
      const { invites } = result;
      const { memberId, profile, memberState, stateUpdatedBy } = invites[0];
      const { lastName, firstName } = profile;
      const approvedUserDetails = {
        firstName,
        lastName,
        _id: memberId,
      };
      Logger(result);
      if (memberState === 'ACTIVE') {
        // dispatch(actions.updateInvitedUserSuccess(approvedUserDetails));
        cb(true, approvedUserDetails);
      } else if (memberState === 'DEACTIVATED') {
        overideCb(approvedUserDetails, stateUpdatedBy);
      }
      // if (result.success) {
      //   const { userInvite, status } = result.data;
      //   if (status === 'ERROR_APPROVING_INVITE') {
      //     const { stateUpdatedBy } = result.data;
      //     overideCb(userInvite, stateUpdatedBy);
      //   } else {
      //     dispatch(actions.updateInvitedUserSuccess(approvedUserDetails));
      //     cb(true, approvedUserDetails);
      //   }
      // } else {
      // }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const approveOverrideInviteUserAction = (props, cb) => {
  const userId = props;
  Logger(`approveInviteUserAction ${userId}`, true);
  // const name = `${user.firstName} ${user.lastName}`;

  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${apiEndpoints.INVITE_USERS}/${userId}/approve`,
        {
          forceOverride: true,
        },
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      if (result.success) {
        const { userInvite } = result.data;
        dispatch(actions.updateInvitedUserSuccess(userInvite));
        cb(true);
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const denyInviteUserAction = ({ memberId, onSuccess }) => {
  Logger(`denyInviteUserAction ${memberId}`, true);
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${apiEndpoints.DELETE_INVITED_USERS}`,
        { memberIds: [memberId] },
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      if (result) {
        onSuccess();
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_INVITED_USERS_SUCCESS: {
      const users = state.users ? state.users : [];
      return {
        ...state,
        users: [...users, ...action.users],
      };
    }
    case GET_INVITED_USERS_SUCCESS:
      return { ...state, users: action.users };
    case UPDATE_INVITED_USER_SUCCESS: {
      const stateUsers = _.get(state, 'users', []);
      return {
        ...state,
        users: stateUsers.map((user) => {
          if (user._id === action.user._id) {
            return {
              ...user,
              ...action.user,
            };
          }
          return user;
        }),
      };
    }

    case UPDATE_INVITED_USERS_SUCCESS: {
      const udpatedUsers = action.invites;
      const stateUsers = _.get(state, 'users', []);
      const users = stateUsers.map((user) => {
        const updatedUser = udpatedUsers.find(
          (u) => user.memberId === u.memberId,
        );
        if (updatedUser) {
          return { ...updatedUser };
        }
        return user;
      });
      return { ...state, users };
    }
    case DELETE_INVITED_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.userId),
      };
    case USER_REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
