import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  GetRewardsResponse,
  GetCheapestRewardResponse,
  GetGiftCardResponse,
  GetDonationResponse,
  GetMyRewardsResponse,
  GetMyRewardsHistoryResponse,
  RewardsFetchQueryParamsType,
  GetIPResponse,
  RedeemPostPayloadType,
  AxomoRedeemReward,
  ShippingDetails,
} from './interfaces';

import {
  GET_CUSTOM_REWARD,
  GET_CHEAPEST_REWARD,
  GET_GIFT_CARDS,
  GET_DONATIONS,
  GET_MY_REWARDS,
  GET_EXCHANGE_RATES,
  GET_MY_REWARDS_HISTORY,
  RESEND_EMAIL_FOR_MY_REWARDS,
  GET_IP,
  CULTURE_REWARD_CASHOUT,
  GET_PROFILE_INFO,
  REWARD_CASHOUT,
  AXOMO_REWARD_CATALOGUE,
  AXOMO_REWARD_CATEGORY,
  GET_AXOMO_REWARD_ITEM,
  GET_GENERAL_REWARDS_INFO,
  GET_AXOMO_SHIPPING_DETAILS,
} from '../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

export const useRewardsFetchQuery = (
  params: RewardsFetchQueryParamsType,
  makeRequest = true,
) => {
  return useQuery(
    GET_CUSTOM_REWARD,
    () => makeAPICallWithDataReturn(GET_CUSTOM_REWARD, undefined, params),
    {
      staleTime: 60 * 30 * 1000,
      select: (res: GetRewardsResponse) => res.data,
      enabled: makeRequest,
    },
  );
};

export const useCheapestRewardQuery = (param: string, makeRequest = true) => {
  return useQuery(
    GET_CHEAPEST_REWARD,
    () =>
      makeAPICallWithDataReturn(GET_CHEAPEST_REWARD, undefined, undefined, {
        countryCode: param,
      }),
    {
      staleTime: 60 * 30 * 1000,
      select: (res: GetCheapestRewardResponse) => res,
      enabled: makeRequest,
    },
  );
};

export const useGiftCardFetchQuery = (
  params: RewardsFetchQueryParamsType,
  makeRequest = true,
) => {
  return useQuery(
    GET_GIFT_CARDS,
    () => makeAPICallWithDataReturn(GET_GIFT_CARDS, undefined, params),
    {
      staleTime: 60 * 30 * 1000,
      select: (res: GetGiftCardResponse) => res.data,
      enabled: makeRequest,
    },
  );
};

export const useDonationFetchQuery = (
  params: RewardsFetchQueryParamsType,
  makeRequest = true,
) => {
  return useQuery(
    GET_DONATIONS,
    () => makeAPICallWithDataReturn(GET_DONATIONS, undefined, params),
    {
      staleTime: 60 * 30 * 1000,
      select: (res: GetDonationResponse) => res.data,
      enabled: makeRequest,
    },
  );
};

export const useMyRewardsFetchQuery = (makeRequest = true) => {
  return useQuery(
    GET_MY_REWARDS,
    () => makeAPICallWithDataReturn(GET_MY_REWARDS),
    {
      staleTime: 60 * 30 * 1000,
      select: (res: GetMyRewardsResponse) => res.data,
      enabled: makeRequest,
    },
  );
};

export const useGetAxomoShippingDetails = (
  orderId: string,
  makeRequest = true,
) => {
  return useQuery<ShippingDetails>(
    [GET_AXOMO_SHIPPING_DETAILS, orderId],
    () =>
      makeAPICallWithDataReturn(
        GET_AXOMO_SHIPPING_DETAILS,
        undefined,
        undefined,
        {
          orderId,
        },
      ),
    {
      staleTime: 60 * 30 * 1000,
      enabled: makeRequest,
    },
  );
};

export const useGetGeneralRewardsInfo = (makeRequest = true) => {
  return useQuery(
    GET_GENERAL_REWARDS_INFO,
    () => makeAPICall(GET_GENERAL_REWARDS_INFO),
    {
      staleTime: 60 * 30 * 1000,
      enabled: makeRequest,
    },
  );
};

export const useExchangeRatesFetchQuery = () => {
  return useMutation((currencyCodes: string[]) => {
    return makeAPICall(GET_EXCHANGE_RATES, {
      currencyCodes,
    });
  });
};

export const useMyRewardsHistoryFetchQuery = () => {
  return useQuery(
    GET_MY_REWARDS_HISTORY,
    () => makeAPICallWithDataReturn(GET_MY_REWARDS_HISTORY),
    {
      staleTime: 60 * 30 * 1000,
      select: (res: GetMyRewardsHistoryResponse) => res.data.history,
    },
  );
};

export const useResendMailMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (orderId: string) => {
      return makeAPICall(RESEND_EMAIL_FOR_MY_REWARDS, {
        orderId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_MY_REWARDS);
      },
    },
  );
};

export const useIPFetchQuery = () => {
  const fetchIPData = async () => {
    const { data } = await axios.get('https://ipapi.co/json/');
    return data;
  };

  return useQuery<GetIPResponse>(GET_IP, fetchIPData, {
    retry: false,
    staleTime: 60 * 30 * 1000,
  });
};

export const useCultureRedeemPostQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (customRewardId: string) =>
      makeAPICall(CULTURE_REWARD_CASHOUT, { customRewardId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PROFILE_INFO);
        queryClient.refetchQueries(GET_MY_REWARDS_HISTORY);
        queryClient.refetchQueries(GET_MY_REWARDS);
      },
    },
  );
};

export const useRedeemPostQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (options: RedeemPostPayloadType) => makeAPICall(REWARD_CASHOUT, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PROFILE_INFO);
        queryClient.refetchQueries(GET_MY_REWARDS_HISTORY);
        queryClient.refetchQueries(GET_MY_REWARDS);
      },
    },
  );
};

export const useAxomoRewardsFetchQuery = (params: any) => {
  return useQuery(
    [AXOMO_REWARD_CATALOGUE, params],
    () => makeAPICallWithDataReturn(AXOMO_REWARD_CATALOGUE, undefined, params),
    {
      staleTime: 60 * 30 * 1000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useAxomoRewardsCategoryQuery = () => {
  return useQuery(
    AXOMO_REWARD_CATEGORY,
    () => makeAPICallWithDataReturn(AXOMO_REWARD_CATEGORY),
    {
      staleTime: 60 * 30 * 1000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useAxomoRewardItemQuery = (
  itemUID: string,
  storeUID: string,
  makeRequest: boolean,
) => {
  return useQuery<AxomoRedeemReward>(
    [GET_AXOMO_REWARD_ITEM, itemUID],
    () =>
      makeAPICallWithDataReturn(GET_AXOMO_REWARD_ITEM, undefined, undefined, {
        storeUID,
        itemUID,
      }),
    {
      staleTime: 60 * 30 * 1000,
      select: (res: any) => res.data,
      refetchOnWindowFocus: false,
      enabled: makeRequest,
    },
  );
};
