import React, { useState, useEffect, useRef } from 'react';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import FileViewer from '../../organism/FileViewer';
import ThemeV2 from '../../../componentsV2/theme';
import { File } from '../../molecules/FileCard/types';
import { Flex } from '../../../Utils/styles/display';
import format from 'date-fns/format';

import {
  VISIBILITY,
  DATE_NOT_FOUND,
  FILE_NOT_FOUND,
  SHARED_IN,
} from '../../../languages/en/feed';

import {
  StyledAvatar,
  StyledBodyHeaderTitle,
  StyledSubheading,
  ButtonsWrapper,
  FileDescription,
  HeaderText,
  StyledIconButton,
  ModalBackground,
  ModalHeading,
  ModalBody,
  ModalWrapper,
  SliderWrapper,
  SliderBox,
  VerticalBar,
  PageSelector,
  PageBreak,
  SideWrapper,
} from './styles';
import { GetFlowFileResponse } from '../../../queries/Flows/interfaces';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { FlowType } from '../FlowsPostHeader/interfaces';
import { VisibilityType } from '../../../queries/Flows/Feed/interfaces';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { YOU, UPLOADED, CUSTOM } from '../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../Utils/text';
import { ENTIRE_ORGANIZATION } from '../../../languages/en';
import {
  ONLY_FLOW_PARTICIPANTS,
  ONLY_OWNER,
} from '../../../languages/en/flows';
import { Slider } from '@material-ui/core';
import {
  imagePreviewFileTypes,
  supportedPreviewFileTypes,
} from '../../../constants/files';

export interface FilePreviewModalProps {
  fileInformation?: File;
  isOpen: boolean;
  fileToPreview?: GetFlowFileResponse;
  person?: FeedPostUserProps | null;
  flow?: FlowType;
  createdAt?: string;
  visibility?: VisibilityType;
  onClickDownload: (data: File) => void;
  onCloseModal: () => void;
  isAnonymous?: boolean;
}

const idPrefix = 'pdf-viewer-page-';

const renderVisibilityText = (visibility: string | undefined) => {
  if (visibility === 'custom') {
    return `${capitalizeFirstLetter(CUSTOM)}`;
  }
  if (visibility === 'everyone') {
    return ENTIRE_ORGANIZATION;
  }
  if (visibility === 'onlyParticipants') {
    return ONLY_FLOW_PARTICIPANTS;
  }
  return ONLY_OWNER;
};

const PageBreakComponent = (props: { index: number }) => {
  const { index } = props;
  return <PageBreak>{index}</PageBreak>;
};

const FilePreviewModal = (props: FilePreviewModalProps) => {
  const {
    fileInformation,
    isOpen,
    fileToPreview,
    onClickDownload,
    onCloseModal,
    person,
    flow,
    createdAt,
    visibility,
    isAnonymous = false,
  } = props;

  const [defaultZoom, setDefaultZoom] = useState(100);
  const [zoomPercent, setZoomPercent] = useState(0);

  const bodyElement = useRef(null);

  useEffect(() => {
    setZoomPercent(defaultZoom);
  }, [defaultZoom]);

  const scrollToPage = (index: number) => {
    const myElement = document.getElementById(idPrefix + index);
    const topPos = myElement && myElement.offsetTop;
    if (bodyElement && bodyElement.current) {
      //@ts-ignore
      bodyElement.current.scrollTop = topPos;
    }
  };

  const PdfSideWrapper = (wrapperProps: {
    index: number;
    children: React.ReactNode;
  }) => {
    const { index, children } = wrapperProps;
    return (
      <SideWrapper onClick={() => scrollToPage(index)}>
        <div style={{ height: '16px' }} />
        {children}
      </SideWrapper>
    );
  };

  const onSliderMove = (event: Event, value: number) => {
    setZoomPercent(value);
  };

  const sliderText = (value: number) => {
    return '' + value;
  };

  const fileType = fileInformation
    ? fileInformation.name.substring(
        fileInformation.name.lastIndexOf('.') + 1,
        fileInformation.name.length,
      )
    : '';

  const isImage = imagePreviewFileTypes.includes(fileType);

  const isSupported = supportedPreviewFileTypes.includes(fileType);

  const zoomPercentOnClickChangeValue = 15;

  const maxZoom = 200;

  return (
    <ModalWrapper isOpen={isOpen} handleClose={onCloseModal}>
      <ModalBackground>
        <ModalHeading>
          <Flex>
            <StyledAvatar
              img={person?.image}
              name={person?.firstName}
              userId={person?.memberID}
              size="40px"
              isAnonymous={isAnonymous}
              {...(isAnonymous && { iconSize: '40px' })}
            />
            <HeaderText>
              <StyledBodyHeaderTitle color="gray9" variant="body2">
                <span style={{ fontWeight: '500' }}>
                  {person?.memberID === '0' ? `${YOU} ` : person?.firstName}
                </span>
                {` ${UPLOADED} `}
                <span style={{ fontWeight: '500' }}>
                  {fileInformation ? fileInformation.name : `${FILE_NOT_FOUND}`}
                </span>
              </StyledBodyHeaderTitle>
              <StyledSubheading color="gray7" variant="body3">
                {createdAt
                  ? format(new Date(createdAt), 'LLL do, yyyy')
                  : `${DATE_NOT_FOUND}`}
                {flow && visibility && (
                  <>
                    {' • '}
                    {SHARED_IN}{' '}
                    {flow ? mapHexCodeToEmoticon(flow.icon.value) : ''}{' '}
                    {flow?.name}
                    {' • '}
                    {`${VISIBILITY}: ` + renderVisibilityText(visibility)}
                  </>
                )}
              </StyledSubheading>
            </HeaderText>
          </Flex>
          <ButtonsWrapper>
            {fileInformation && (
              <>
                <StyledIconButton
                  height={32}
                  icon="download"
                  size={ButtonSize.Normal}
                  iconColor={ThemeV2.palette.gray6}
                  onClick={() => onClickDownload(fileInformation)}
                />
                <VerticalBar height={32} sideMargin={16} />
              </>
            )}
            <StyledIconButton
              height={32}
              icon="close-rounded"
              onClick={onCloseModal}
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray6}
            />
          </ButtonsWrapper>
        </ModalHeading>
        {isSupported && <FileDescription hasMessage={false} />}
        <ModalBody fullHeight={isSupported === false}>
          {fileType === 'pdf' && (
            <PageSelector>
              <FileViewer
                fileType={fileType}
                //@ts-ignore
                filePath={fileToPreview?.data.url}
                zoomPercent={zoomPercent}
                setDefaultZoom={setDefaultZoom}
                SubComponent={PageBreakComponent}
                WrapperComponent={PdfSideWrapper}
                hideLoader={true}
              />
            </PageSelector>
          )}
          <FileViewer
            fileType={fileType}
            //@ts-ignore
            filePath={fileToPreview?.data.url}
            zoomPercent={zoomPercent}
            setDefaultZoom={setDefaultZoom}
            viewerRef={bodyElement}
            idPrefix={idPrefix}
          />
        </ModalBody>
        {isImage && (
          <SliderBox>
            <StyledIconButton
              height={24}
              icon="zoom-out"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray8}
              onClick={() => {
                if (zoomPercent - zoomPercentOnClickChangeValue > defaultZoom) {
                  setZoomPercent(zoomPercent - zoomPercentOnClickChangeValue);
                } else {
                  setZoomPercent(defaultZoom);
                }
              }}
            />
            <SliderWrapper>
              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={zoomPercent}
                //@ts-ignore
                onChange={onSliderMove}
                valueLabelDisplay="auto"
                getAriaValueText={sliderText}
                track={false}
                min={defaultZoom}
                max={maxZoom}
              />
            </SliderWrapper>
            <StyledIconButton
              height={24}
              icon="zoom-in"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray8}
              onClick={() => {
                if (zoomPercent + zoomPercentOnClickChangeValue < maxZoom) {
                  setZoomPercent(zoomPercent + zoomPercentOnClickChangeValue);
                } else {
                  setZoomPercent(maxZoom);
                }
              }}
            />
            <VerticalBar height={24} sideMargin={8} />
            <StyledIconButton
              height={24}
              icon="reset-zoom"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray6}
              onClick={() => setZoomPercent(defaultZoom)}
            />
          </SliderBox>
        )}
      </ModalBackground>
    </ModalWrapper>
  );
};
export default FilePreviewModal;
