import React from 'react';
import { Redirect } from 'react-router-dom';

import { SIGN_IN } from '../../../constants/routes';

import ParticipationFlowHeader from '../../../atomic/molecules/FlowsParticipationHeader';
import { StyledBodyHeaderTitle } from '../../../atomic/molecules/FlowsParticipationHeader/styles';
import {
  ExternalParticipationTemplateErrorTypes,
  ParticipationTemplateErrorTypes,
} from '../../../atomic/pages/ParticipationTemplate/types';

import {
  LoadingParticipationTemplate,
  ParticipationTemplateError,
} from '../../../atomic/pages/ParticipationTemplate';

import { ErrorTemplateWrapper } from './styles';
import { Flex } from '../../../Utils/styles/display';

import { ComponentStatus } from '../../../interfaces/component';
import useFlowsParticipationController from './useFlowsParticipationController';
import {
  mapParticipationTemplateErrorTypesToDescriptionMessage,
  mapParticipationTemplateErrorTypesToTitleMessage,
} from '../../../Utils/flows';
import LoadedFlowsParticipationFlowController from './LoadedFlowsParticipationController';
import { ErroredExternalFlows } from '../ExternalFlowsController';
import { FlowVariants } from '../../../interfaces/Flow';
import Body from '../../../atomic/atoms/Body';
import { YOU } from '../../../languages/en/home';
import { ARE_ANSWERING } from '../../../languages/en/flows/participation';
import AssemblyLanderLoadingScreen from '../../../atomic/molecules/AssemblyLanderLoadingScreen';

const FlowsParticipationController = () => {
  const {
    flowId,
    creator,
    flowData,
    isLoading,
    identifier,
    profileData,
    customError,
    headerStatus,
    errorMessage,
    isRedirected,
    onFlowSubmit,
    isPrivatePost,
    setCustomError,
    isAnonymousPost,
    validationError,
    isMutationLoading,
    flowHeaderContent,
    togglePrivatePost,
    participationType,
    toggleAnonymousPost,
    isUnAuthorizedError,
    flowDetailsInstanceData,
    handleCloseParticipationModal,
  } = useFlowsParticipationController();

  if (customError) {
    return (
      <ParticipationTemplateError
        member={profileData.member}
        onCloseModal={() => {}}
        flowId={flowId}
        templateError={customError}
        headerStatus={headerStatus}
        flowHeaderContent={flowHeaderContent}
        onAnswerWithShortcutButtonClick={() => {}}
        setCustomError={setCustomError}
      />
    );
  }

  if (isLoading) {
    if (isRedirected) {
      return <AssemblyLanderLoadingScreen />;
    }

    return (
      <LoadingParticipationTemplate
        isFullScreen
        flowId={flowId}
        onCloseModal={() => {}}
        member={profileData.member}
        headerStatus={ComponentStatus.LOADING}
        flowHeaderContent={flowHeaderContent}
      />
    );
  }

  if (!isLoading && errorMessage) {
    return (
      <Flex flexDirection="column" height="100vh">
        <ParticipationFlowHeader
          onCloseModal={() => {}}
          status={ComponentStatus.LOADED}
          member={profileData.member}
          isAnonymous={participationType === `EXTERNAL`}
          showCloseModalOption={false}
          flowHeaderContent={{
            TitleContent: (
              <Flex>
                {flowData?.name && (
                  <StyledBodyHeaderTitle color="gray8" variant="body2">
                    <Body color="geekblue6" inline variant="body2Medium">
                      {`${YOU} `}
                    </Body>
                    {`${ARE_ANSWERING} `}
                  </StyledBodyHeaderTitle>
                )}
                <StyledBodyHeaderTitle
                  color={flowData?.name ? 'geekBlue6' : 'gray8'}
                  variant="body2"
                >
                  {mapParticipationTemplateErrorTypesToTitleMessage(
                    errorMessage as ParticipationTemplateErrorTypes,
                    flowData,
                  )}
                </StyledBodyHeaderTitle>
              </Flex>
            ),
            DescriptionContent:
              mapParticipationTemplateErrorTypesToDescriptionMessage(
                errorMessage as ParticipationTemplateErrorTypes,
                flowData,
              ),
          }}
        />
        <ErrorTemplateWrapper>
          <ParticipationTemplateError
            member={profileData.member}
            onCloseModal={() => {}}
            flowId={flowId}
            templateError={errorMessage as ParticipationTemplateErrorTypes}
            headerStatus={ComponentStatus.LOADED}
            flowHeaderContent={{
              TitleContent: <></>,
              DescriptionContent: '',
            }}
            returnOnlyContent
          />
        </ErrorTemplateWrapper>
      </Flex>
    );
  }

  if (isUnAuthorizedError && validationError && validationError.code !== 409) {
    return <Redirect to={SIGN_IN} />;
  }

  if (isUnAuthorizedError && validationError && validationError.code === 409) {
    if (
      validationError.message ===
      ExternalParticipationTemplateErrorTypes.ACCESS_DENIED
    ) {
      return <Redirect to={SIGN_IN} />;
    }

    return (
      <ErroredExternalFlows
        flowId={flowId}
        errorType={validationError?.message}
      />
    );
  }

  if (!isLoading && flowDetailsInstanceData) {
    return (
      <LoadedFlowsParticipationFlowController
        identifier={identifier}
        profileInfo={profileData}
        participationType={participationType}
        flowInstance={flowDetailsInstanceData}
        toggleParticipationModalOpen={handleCloseParticipationModal}
        headerStatus={headerStatus}
        flowHeaderContent={flowHeaderContent}
        isPrivatePost={isPrivatePost}
        isAnonymousPost={isAnonymousPost || participationType === 'EXTERNAL'}
        toggleAnonymousPost={toggleAnonymousPost}
        togglePrivatePost={togglePrivatePost}
        isMutationLoading={isMutationLoading}
        onFlowSubmit={onFlowSubmit}
        creator={creator}
        flowVariant={
          participationType === 'EXTERNAL'
            ? FlowVariants.EXTERNAL_FLOW
            : FlowVariants.PARTICIPATION_FLOW
        }
      />
    );
  }

  return null;
};

export default FlowsParticipationController;
