import React, { useMemo, memo, useCallback, useState } from 'react';
import { ComponentStatus } from '../../../../interfaces/component';
import {
  Assignee,
  NotebookTask,
  TaskCategories,
} from '../../../../interfaces/notebook';
import {
  NOTEBOOK_TASK_DETAILS_HEADER,
  NOTEBOOK_TASK_DETAILS_URL_DETAILS,
  CREATED_BY,
  DUE_DATE,
  ASSIGNEE,
  TASK_HISTORY,
  ARCHIVED_DATE,
  COMPLETED_DATE,
  NOTEBOOK_TASK_DETAILS_ERROR,
  DELETED_TASK_BANNER,
  DESCRIPTION_PLACEHOLDER,
  ABOUT_NOTEBOOK_TITLE,
  ABOUT_NOTEBOOK_DESCRIPTION_1,
  ABOUT_NOTEBOOK_DESCRIPTION_2,
  SEE_MORE,
  NOTEBOOK_TASK_DETAILS_ACCESS_ERROR,
  LINK_COPY_SUCCESS_TOAST,
} from '../../../../languages/en/notebook';
import ThemeV2 from '../../../../componentsV2/theme';
import {
  DESCRIPTION,
  ON,
  TASK,
  YOU,
} from '../../../../languages/en/singleWords';
import {
  convertStringToTokenizedObject,
  convertTokenizedObjectToString,
} from '../../../../Utils/notebook';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import Button from '../../../atoms/Button';
import Image from '../../../atoms/Image';
import {
  NotebookRightDrawerAssigneeLoader,
  NotebookRightDrawerDateLoader,
  NotebookRightDrawerTaskHistoryLoader,
  NotebookRightDrawerTaskLoader,
} from '../../../molecules/Notebook/Loader';
import TaskItemDatePicker from '../../../molecules/Notebook/TaskItem/TaskItemDatePicker';
import TaskItemTextArea from '../../../molecules/Notebook/TaskItem/TaskItemTextArea';
import {
  Wrapper,
  Block,
  BlockTitle,
  CreatedByText,
  ErrorTitle,
  ErrorSubtitle,
  Header,
  Heading,
  DescriptionPlaceholderText,
  TextAreaWrapper,
  ShowMoreButton,
  AssigneeWrapper,
  Description,
} from './styles';
import RightDrawerErrorImage from '../../../../img/home/right-drawer-error.svg';
import RightDrawerAccessError from '../../../atoms/SVGIcon/icons/clip-data-protection.svg';
import { Flex } from '../../../../Utils/styles/display';
import SVGIcon from '../../../atoms/SVGIcon';
import { getTaskCategoryBasedOnDueDate } from '../../../../controllers/notebook/NotebookPageController/utils';
import format from 'date-fns/format';
import { FORMAT_DATE } from '../../../../constants/notebook';
import InfoAlert from '../../../molecules/InfoAlert';
import TaskContent from '../../../molecules/Notebook/TaskContent';
import { isEmpty } from '../../../../Utils/common';
import TaskItemAssigneeSelector from '../../../molecules/Notebook/TaskItemAssigneeSelector';
import { NotebookTabs } from '../../../../controllers/notebook/NotebookPageController/useNotebookPageController/types';
import { getFullName } from '../../../../Utils/user';
import TaskHistory from '../../../molecules/Notebook/TaskHistory';
import { TaskHistoryActivity } from '../../../molecules/Notebook/TaskHistory/interface';
import IconButton from '../../../atoms/IconButton_V2';
import { ButtonSize } from '../../../atoms/IconButton_V2/interface';
import { SelectedMember } from '../../../../interfaces/Layout';
import Body from '../../../atoms/Body';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { showSuccessMessage } from '../../../../Utils/toast';

const customBannerVariant = {
  background: ThemeV2.palette.dustRed2,
  border: ThemeV2.palette.dustRed6,
  color: ThemeV2.palette.darkGray9,
  focusColor: ThemeV2.palette.dustRed6,
  iconColor: ThemeV2.palette.dustRed6,
};

export enum ReadOnlyComponentStatus {
  READ_ONLY = 'readOnly',
}

type LoadedNotebookTaskDetailsProps = {
  handleUserClick: (member: SelectedMember) => void;
  toggleHistoryLogSort: () => void;
  historyLogSort: string;
  fetchMoreLogs: () => void;
  hasMoreLogsToFetch?: boolean;
  isFetchingMoreLogs: boolean;
  taskHistoryList: TaskHistoryActivity[];
  isTaskHistoryLoading: boolean;
  currentUserId: string;
  task: NotebookTask;
  status: ComponentStatus.LOADED;
  editTaskContent: (updatedTask: NotebookTask) => void;
  handleTaskUpdate: (
    updatedTask: NotebookTask,
    previousCategory: TaskCategories,
  ) => void;
  setTaskIdOfFocusedTask: (taskId: string, isDelete?: boolean) => void;
  fetchMoreAssignees: () => void;
  assigneesList: Assignee[] | undefined;
  hasMoreAssigneesToFetch: boolean;
  isFetchingMoreAssignees: boolean;
  searchValue: string;
  handleSearchOnAssigneeList: (searchValue: string) => void;
  tab: NotebookTabs;
  handleAssigneeChange: (
    task: NotebookTask,
    oldAssignee: Assignee,
    newAssignee: Assignee,
  ) => void;
  currentUserTimezone: string;
};

type LoadingNotebookTaskDetailsProps = {
  status: ComponentStatus.LOADING;
};

type ErrorNotebookTaskDetailsProps = {
  status: ComponentStatus.ERROR;
  isAccessError: boolean;
};

type ReadOnlyNotebookTaskDetailsProps = {
  status: ReadOnlyComponentStatus.READ_ONLY;
};

const LoadedNotebookTaskDetails = ({
  historyLogSort,
  toggleHistoryLogSort,
  currentUserId,
  tab,
  task,
  assigneesList,
  hasMoreAssigneesToFetch,
  searchValue,
  isFetchingMoreAssignees,
  currentUserTimezone,
  setTaskIdOfFocusedTask,
  fetchMoreAssignees,
  handleSearchOnAssigneeList,
  editTaskContent,
  handleTaskUpdate,
  isTaskHistoryLoading,
  taskHistoryList,
  handleAssigneeChange,
  fetchMoreLogs,
  hasMoreLogsToFetch,
  isFetchingMoreLogs,
  handleUserClick,
}: LoadedNotebookTaskDetailsProps) => {
  const { note, createdAt, dueDate, type, isDeleted, description, createdBy } =
    task;
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [descriptionEditMode, setDescriptionEditMode] = useState(false);

  const { treatment: notebookTreatment } = useFeatureSplit(
    SplitNames.NOTEBOOK_RIGHT_DRAWER,
  );

  const isNotebookRightDrawerAvailable =
    notebookTreatment === TreatmentTypes.ON;

  const serializedTitle = useMemo(
    () => convertTokenizedObjectToString(note),
    [note],
  );

  const serializedDescription = useMemo(
    () => convertTokenizedObjectToString(description),
    [description],
  );

  const { assignedTo, taskId, noteId } = task;

  const createdByName = createdBy
    ? createdBy.memberID === currentUserId
      ? YOU.toLocaleLowerCase()
      : getFullName(createdBy)
    : YOU.toLocaleLowerCase();

  const dueDateLabel = useMemo(() => {
    if (task.type === TaskCategories.ARCHIVED) return ARCHIVED_DATE;
    if (task.type === TaskCategories.COMPLETED) return COMPLETED_DATE;
    return DUE_DATE;
  }, [task]);

  const onTitleChange = useCallback(
    (newTitleValue: string) => {
      setTitleEditMode(false);
      if (newTitleValue && newTitleValue !== serializedTitle) {
        editTaskContent({
          ...task,
          note: convertStringToTokenizedObject(newTitleValue),
        });
      }
    },
    [editTaskContent, serializedTitle, task],
  );

  const handleAssigneeSelection = useCallback(
    (assignee: Assignee) => {
      const updatedTask = { ...task, assignedTo: assignee };
      handleAssigneeChange(updatedTask, assignedTo, assignee);
    },
    [assignedTo, handleAssigneeChange, task],
  );

  const onDescriptionChange = useCallback(
    (newDescriptionValue: string) => {
      setDescriptionEditMode(false);
      if (newDescriptionValue !== serializedDescription) {
        editTaskContent({
          ...task,
          description: isEmpty(newDescriptionValue.trimEnd())
            ? []
            : convertStringToTokenizedObject(newDescriptionValue.trimEnd()),
        });
      }
    },
    [editTaskContent, serializedDescription, task],
  );

  const setDueDate = useCallback(
    (updatedDueDate: string | undefined) => {
      const previousCategory = task.type;
      const updatedCategory = getTaskCategoryBasedOnDueDate(
        updatedDueDate,
        currentUserTimezone,
      );
      const updatedTask: NotebookTask = {
        ...task,
        dueDate: updatedDueDate,
        type: updatedCategory,
      };
      handleTaskUpdate(updatedTask, previousCategory);
    },
    [task, currentUserTimezone, handleTaskUpdate],
  );

  const renderTitleEditor = () => {
    return (
      <TextAreaWrapper
        onClick={() => {
          setTitleEditMode(true);
        }}
        isDisabled={isDeleted}
      >
        {titleEditMode ? (
          <TaskItemTextArea
            autofocus
            serializedTitle={serializedTitle}
            onBlur={onTitleChange}
            handleEnterClick={onTitleChange}
            disabled={isDeleted}
            stopPropagation={false}
            variant="custom"
            hasHoverEffect
          />
        ) : (
          <TaskContent
            strikeThroughOverride={false}
            content={note}
            disabled={false}
            stopPropagation={false}
            hasHoverEffect
          />
        )}
      </TextAreaWrapper>
    );
  };

  const renderDescriptionEditor = () => {
    return (
      <TextAreaWrapper
        onClick={() => {
          setDescriptionEditMode(true);
        }}
        isDisabled={isDeleted}
      >
        {descriptionEditMode ? (
          <TaskItemTextArea
            autofocus
            serializedTitle={serializedDescription}
            onBlur={onDescriptionChange}
            handleEnterClick={onDescriptionChange}
            disabled={isDeleted}
            stopPropagation={false}
            variant="custom"
            enableShiftNewLine
            hasHoverEffect
          />
        ) : (
          <Description>
            {isEmpty(serializedDescription) && (
              <DescriptionPlaceholderText variant="body1" color="gray6">
                {DESCRIPTION_PLACEHOLDER}
              </DescriptionPlaceholderText>
            )}
            <TaskContent
              strikeThroughOverride={false}
              content={description}
              disabled={false}
              stopPropagation={false}
              noPadding={false}
              hasHoverEffect
            />
          </Description>
        )}
      </TextAreaWrapper>
    );
  };

  return (
    <Wrapper>
      <Header>
        <SVGIcon icon="task-checklist" size="36px" />
        <Heading variant="body1">{NOTEBOOK_TASK_DETAILS_HEADER}</Heading>
      </Header>
      <Block>
        <Button
          icon="Link"
          color="secondary"
          onClick={() => {
            showSuccessMessage(LINK_COPY_SUCCESS_TOAST);
            navigator.clipboard.writeText(
              `${window.location.origin}/notebook/${tab}?taskId=${task.noteId}`,
            );
          }}
          isFullWidth
        >
          {NOTEBOOK_TASK_DETAILS_URL_DETAILS}
        </Button>
      </Block>
      {isDeleted && (
        <Block>
          <InfoAlert
            id={+new Date()}
            text={DELETED_TASK_BANNER}
            icon="error-warning"
            alertType="custom"
            customVariant={customBannerVariant}
          />
        </Block>
      )}
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {capitalizeFirstLetter(TASK)}
        </BlockTitle>
        {renderTitleEditor()}
      </Block>
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {capitalizeFirstLetter(DESCRIPTION)}
        </BlockTitle>
        {renderDescriptionEditor()}
      </Block>
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {dueDateLabel}
        </BlockTitle>
        <TaskItemDatePicker
          dueDate={dueDate}
          setDueDate={setDueDate}
          taskType={type}
          isDeleted={isDeleted}
          currentUserTimezone={currentUserTimezone}
        />
      </Block>
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {ASSIGNEE}
        </BlockTitle>
        <AssigneeWrapper
          onClick={() => {
            setTaskIdOfFocusedTask(noteId);
          }}
          isDisabled={isDeleted}
        >
          <TaskItemAssigneeSelector
            assigneeList={assigneesList || []}
            isLoading={isFetchingMoreAssignees}
            onMemberClick={handleAssigneeSelection}
            hasMoreUsersToFetch={hasMoreAssigneesToFetch}
            canPopoverTriggerByClick
            position
            fetchMoreMembers={fetchMoreAssignees}
            id={`${taskId}`}
            assignedTo={assignedTo}
            searchValue={searchValue}
            handleSearchOnAssigneeList={handleSearchOnAssigneeList}
            setTaskIdOfFocusedTask={setTaskIdOfFocusedTask}
            noPadding
            currentUserId={currentUserId}
          />
        </AssigneeWrapper>
      </Block>
      {isNotebookRightDrawerAvailable && (
        <Block>
          <BlockTitle variant="body1" color="gray7">
            <Flex justifyContent="space-between">
              {TASK_HISTORY}
              <IconButton
                icon={historyLogSort === 'desc' ? 'arrow-down' : 'arrow-up'}
                size={ButtonSize.Small}
                iconColor="gray8"
                onClick={toggleHistoryLogSort}
              />
            </Flex>
          </BlockTitle>
          {currentUserId && (
            <TaskHistory
              taskHistory={taskHistoryList}
              isLoading={isTaskHistoryLoading}
              handleUserClick={handleUserClick}
            />
          )}
          {hasMoreLogsToFetch && (
            <ShowMoreButton
              onClick={fetchMoreLogs}
              color="primary"
              variant="text"
              icon="arrow-down"
              isFullWidth
              isLoading={isFetchingMoreLogs}
            >
              {SEE_MORE}
            </ShowMoreButton>
          )}
        </Block>
      )}
      <Block>
        <CreatedByText inline variant="body3" color="gray7">
          {CREATED_BY}
          {` ${createdByName} ${ON} ${format(
            new Date(createdAt),
            FORMAT_DATE,
          )}`}
        </CreatedByText>
      </Block>
    </Wrapper>
  );
};

const LoadingNotebookTaskDetails = () => {
  return (
    <Wrapper>
      <Header>
        <SVGIcon icon="task-checklist" size="36px" />
        <Heading variant="body1">{NOTEBOOK_TASK_DETAILS_HEADER}</Heading>
      </Header>
      <Block>
        <Button
          icon="Link"
          color="secondary"
          onClick={() => {}}
          disabled
          isFullWidth
        >
          {NOTEBOOK_TASK_DETAILS_URL_DETAILS}
        </Button>
      </Block>
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {capitalizeFirstLetter(TASK)}
        </BlockTitle>
        <NotebookRightDrawerTaskLoader />
      </Block>
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {DUE_DATE}
        </BlockTitle>
        <NotebookRightDrawerDateLoader />
      </Block>
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {ASSIGNEE}
        </BlockTitle>
        <NotebookRightDrawerAssigneeLoader />
      </Block>
      <Block>
        <BlockTitle variant="body1" color="gray7">
          {TASK_HISTORY}
        </BlockTitle>
        <NotebookRightDrawerTaskHistoryLoader />
      </Block>
    </Wrapper>
  );
};

const ErrorNotebookTaskDetails = ({
  isAccessError,
}: {
  isAccessError: boolean;
}) => {
  return (
    <Wrapper>
      <Header>
        <SVGIcon icon="task-checklist" size="36px" />
        <Heading variant="body1">{NOTEBOOK_TASK_DETAILS_HEADER}</Heading>
      </Header>
      <Flex justifyContent="center" flexDirection="column">
        <Image
          alt="error"
          src={isAccessError ? RightDrawerAccessError : RightDrawerErrorImage}
        />
        <ErrorTitle variant="body1Bold">
          {isAccessError
            ? NOTEBOOK_TASK_DETAILS_ACCESS_ERROR.TITLE
            : NOTEBOOK_TASK_DETAILS_ERROR.TITLE}
        </ErrorTitle>
        <ErrorSubtitle variant="body2">
          {isAccessError
            ? NOTEBOOK_TASK_DETAILS_ACCESS_ERROR.SUB_TITLE
            : NOTEBOOK_TASK_DETAILS_ERROR.SUB_TITLE}
        </ErrorSubtitle>
      </Flex>
    </Wrapper>
  );
};

const NotebookTaskDetailsInfo = () => {
  return (
    <Wrapper>
      <Header>
        <SVGIcon icon="info" size="36px" />
        <Heading variant="body1">{ABOUT_NOTEBOOK_TITLE}</Heading>
      </Header>
      <Flex justifyContent="center" flexDirection="column">
        <Body variant="body1" color="gray9">
          {ABOUT_NOTEBOOK_DESCRIPTION_1}
        </Body>
        <Body variant="body1" color="gray9" margin="16px 0">
          {ABOUT_NOTEBOOK_DESCRIPTION_2}
        </Body>
      </Flex>
    </Wrapper>
  );
};

const NotebookTaskDetails = (
  props:
    | LoadingNotebookTaskDetailsProps
    | LoadedNotebookTaskDetailsProps
    | ErrorNotebookTaskDetailsProps
    | ReadOnlyNotebookTaskDetailsProps,
) => {
  switch (props.status) {
    case ComponentStatus.LOADING: {
      return <LoadingNotebookTaskDetails />;
    }

    case ComponentStatus.LOADED: {
      return <LoadedNotebookTaskDetails {...props} />;
    }

    case ComponentStatus.ERROR: {
      return <ErrorNotebookTaskDetails isAccessError={props.isAccessError} />;
    }

    case ReadOnlyComponentStatus.READ_ONLY: {
      return <NotebookTaskDetailsInfo />;
    }
  }
};

export default memo(NotebookTaskDetails);
