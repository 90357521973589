import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ThemeV2 from '../../../componentsV2/theme';
import {
  AvatarWrapper,
  StyledCard,
  StyledBody,
  StyledImage,
  StyledSVGIcon,
  CardTop,
  CardBottom,
  NameWrapper,
  StyledDropdownIconButton,
} from './styles';
import Body from '../../atoms/Body';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import Avatar from '../../atoms/Avatar';
import { Flex } from '../../../Utils/styles/display';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { menuItemsWithoutDeletePost } from './data';
import { useHistory } from 'react-router-dom';
import { V3_POST_PAGE } from '../../../constants/routes';
import { FeedAttachmentItemFromAPI } from '../../../interfaces/Feed';

const KnowledgeCenterCard = (props: {
  card: FeedAttachmentItemFromAPI;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI) => void;
  openPreviewer: Dispatch<SetStateAction<boolean>>;
  onClickDownload: (cardFile: FeedAttachmentItemFromAPI) => Promise<void>;
  copyPostLink: (flowId: string, responseId: string) => void;
}) => {
  const {
    card,
    handleSetSelectedFile,
    openPreviewer,
    onClickDownload,
    copyPostLink,
  } = props;
  const [cardColor, setCardColor] = useState('');
  const [cardIcon, setCardIcon] = useState<string>();
  const [isHovered, setIsHovered] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    switch (card.fileInfo?.type) {
      case 'doc':
      case 'docx':
        setCardColor(ThemeV2.palette.blue7);
        setCardIcon('docFile');
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'svg':
      case 'bmp':
      case 'gif':
        setCardColor(ThemeV2.palette.blue8);
        setCardIcon('imageFile');
        break;
      case 'ppt':
      case 'pptx':
      case 'pps':
      case 'ppsx':
        setCardColor(ThemeV2.palette.volcano6);
        setCardIcon('presentationFile');
        break;
      case 'pdf':
        setCardColor(ThemeV2.palette.red6);
        setCardIcon('pdfFile');
        break;
      case 'xls':
      case 'xlsx':
      case 'csv':
        setCardColor(ThemeV2.palette.green7);
        setCardIcon('csvFile');
        break;
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ogg':
        setCardColor(ThemeV2.palette.orange6);
        setCardIcon('audioFile');
        break;
      case 'mp4':
      case 'avi':
      case 'mov':
      case 'mpg':
      case 'ogv':
      case '3gp':
      case '3d2':
        setCardColor(ThemeV2.palette.red7);
        setCardIcon('videoFile');
        break;
      case 'zip':
        setCardColor(ThemeV2.palette.gray8);
        setCardIcon('zipFile');
        break;
      default:
        setCardColor(ThemeV2.palette.gray6);
        setCardIcon('genericFile');
        break;
    }
  }, [card.fileInfo?.type]);

  const cardDate = format(
    parseISO(card.fileInfo?.createdAt || ''),
    'MMMM do, yyyy',
  );

  const onOptionsSelect = (value: string | number) => {
    if (value === 'copy-link' && copyPostLink) {
      copyPostLink(card.response?.flowId, card.response?.responseId);
    }
    if (value === 'go-to-post') {
      push(
        `${V3_POST_PAGE}`.replace(
          ':postType',
          `flows?flowId=${card.response.flowId}&responseId=${card.response.responseId}`,
        ),
      );
    }
    if (value === 'download-file' && onClickDownload) {
      onClickDownload(card);
    }
    if (value === 'delete') {
      console.log('Not implemented on the BE yet.');
    }
  };

  return (
    <StyledCard
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        openPreviewer(true);
        handleSetSelectedFile(card);
      }}
    >
      <CardTop backgroundColor={cardColor}>
        {cardIcon && <StyledSVGIcon icon={cardIcon} size="32px" />}
        {isHovered && (
          <StyledDropdownIconButton
            color="tertiary"
            iconColor={ThemeV2.palette.gray8}
            menuItems={menuItemsWithoutDeletePost}
            icon={'more'}
            title={'file card options'}
            onItemClick={onOptionsSelect}
            size={ButtonSize.Normal}
            stopEventPropagation={true}
          />
        )}
        {card.fileInfo?.thumbnails && cardIcon === 'imageFile' && (
          <StyledImage
            alt={card.fileInfo?.name}
            src={card.fileInfo?.thumbnails['360']}
          />
        )}
      </CardTop>
      <CardBottom>
        <NameWrapper>
          <StyledBody variant="body2Medium">
            {card.fileInfo?.name.split('.')[0]}
          </StyledBody>
          <Body variant="body2Medium">.{card.fileInfo?.type}</Body>
        </NameWrapper>
        <StyledBody
          color={ThemeV2.palette.gray8}
          style={{ marginTop: '8px' }}
          variant="body3"
        >
          Shared on {cardDate}
        </StyledBody>
        <StyledBody
          color={ThemeV2.palette.gray8}
          style={{ marginTop: '4px' }}
          variant="body3"
        >
          <Flex>
            <AvatarWrapper>
              <Avatar
                img={card.respondent?.image}
                name={card.respondent?.name}
                userId={card.respondent?.memberID}
                size="16px"
                iconSize="16px"
                verticalAlign="baseline"
              />
            </AvatarWrapper>
            {card.respondent?.name}
          </Flex>
        </StyledBody>
      </CardBottom>
    </StyledCard>
  );
};

export default KnowledgeCenterCard;
