import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

import Body from '../../atoms/Body';
import Image from '../../atoms/Image';
import SVGIcon from '../../atoms/SVGIcon';
import DropdownIconButton from '../DropdownIconButton';

interface CardTopProps {
  backgroundColor: string;
}

export const StyledCard = styled.div`
  position: relative;
  min-width: 242px;
  max-width: 368px;
  width: 100%;
  height: 204px;
  border: 1px ${ThemeV2.palette.gray4} solid;
  border-radius: 4px;
  flex-grow: 1;
  flex-basis: 0;
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.015);
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
`;

export const StyledImage = styled(Image)`
  height: 96px;
  width: 100%;
  border-radius: 3px 3px 0px 0px;
  object-fit: cover;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  position: absolute;
  margin: 8px 0px 0px 8px;
`;

export const CardTop = styled.div<CardTopProps>`
  width: 100%;
  height: 96px;
  border-radius: 3px 3px 0px 0px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const CardBottom = styled.div`
  width: 100%;
  padding: 16px;
`;

export const CardHeader = styled.div`
  position: relative;
`;

export const StyledBody = styled(Body)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameWrapper = styled.div`
  display: flex;
`;

export const AvatarWrapper = styled.div`
  margin-right: 4px;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  position: absolute;
  right: 0px;
  margin-top: 8px;
  margin-right: 8px;
`;

CardHeader.displayName = 'CardHeader';
