import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { parse } from 'qs';
import { GET_FLOWS_TODO_NOTIFICATION_TEXT } from '../../languages/en/flows';
import { useGetFlowsTodoCountQuery } from '../../queries/ActionBar';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { addCelebrateTeammate, mapFlowsToActionBarItem } from './utils';
import useModalsStore from '../../stores/modalsStore';
import {
  setCreateFlowModalOpenSelector,
  setParticipationFlowSelector,
} from '../../stores/modalsStore/selectors';
import { FLOWS_FEEDS, RECOGNITION_PARTICIPATE } from '../../constants/routes';
import useToggle from '../../hooks/useToggle';
import useTrackMainFeedActionBarEvent from '../../hooks/analytics/useTrackMainFeedActionBarEvent';
import {
  MAIN_FEED_ACTION_BAR_EVENTS,
  TEMPLATE_GALLERY_EVENTS,
} from '../../Utils/analytics/constants';
import useGetActionBarData from '../../hooks/useGetActionBarData';
import { trackTemplateGalleryShowEvent } from '../../Utils/analytics/templateGallery';
import { HALF_SECOND } from '../../Utils/constants/times';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';
import { CELEBRATE_TEAMMATE_FLOW_ID } from '../../constants/common';

const useActionBarControllerLogic = () => {
  const { search } = useLocation();
  const history = useHistory();

  const { treatment: pageParticipationTreatment } = useFeatureSplit(
    SplitNames.NEW_PAGE_PARTICIPATION_VIEW,
  );

  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });
  const openActionBarOnLoad =
    parsedParams && parsedParams?.openActionBar === 'true';

  const [searchText, setSearchText] = useState<string>('');
  const [apiSearchText, setApiSearchText] = useState<string>('');
  const { push } = useHistory();
  const {
    isLoading: isActionBarDataLoading,
    isError,
    todo,
    shortcut,
    flowsList,
    isSuccess,
  } = useGetActionBarData(apiSearchText);

  const { data: todoCountData } = useGetFlowsTodoCountQuery();

  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);
  const {
    data: profileData,
    isLoading: isProfileDataLoading,
    refetch: refetchProfileInfoQuery,
  } = useProfileInfoFetchQuery();
  const setCreateFlowModalOpen = useModalsStore(setCreateFlowModalOpenSelector);

  const isLoading = isActionBarDataLoading || isProfileDataLoading;

  const previousUrl = window.history.state?.prevUrl;

  useEffect(() => {
    if (previousUrl) {
      refetchProfileInfoQuery();
    }
  }, [previousUrl, refetchProfileInfoQuery]);

  const todoNotification = useMemo(() => {
    if (todoCountData && todoCountData.count) {
      return GET_FLOWS_TODO_NOTIFICATION_TEXT(todoCountData.count);
    }
    return '';
  }, [todoCountData]);

  const todos = useMemo(() => {
    if (todo && todo.data) {
      return mapFlowsToActionBarItem(todo.data);
    }
    return [];
  }, [todo]);

  const shortcuts = useMemo(() => {
    if (shortcut && shortcut.data) {
      const allShortcuts = addCelebrateTeammate(shortcut.data, apiSearchText);

      return mapFlowsToActionBarItem(allShortcuts);
    }
    return [];
  }, [shortcut, apiSearchText]);

  const feeds = useMemo(() => {
    if (flowsList && flowsList?.data) {
      const allViewFeeds = addCelebrateTeammate(flowsList.data, apiSearchText);
      return mapFlowsToActionBarItem(allViewFeeds);
    }
    return [];
  }, [flowsList, apiSearchText]);

  const { trackMainFeedActionBarEvent } = useTrackMainFeedActionBarEvent({
    numToDoFlows: todos.length,
    numShortcutFlows: shortcuts.length,
  });

  const debounceSearchText = useRef(
    debounce((newValue: string) => {
      setApiSearchText(newValue);
      trackMainFeedActionBarEvent(
        MAIN_FEED_ACTION_BAR_EVENTS.ACTION_BAR_DROPDOWN_SEARCHED,
        {
          searchText: newValue,
        },
      );
    }, HALF_SECOND),
  );

  const handleSearchTextChange = useCallback((newValue: string) => {
    setSearchText(newValue);
    debounceSearchText.current(newValue);
  }, []);

  const clearSearchText = () => {
    setSearchText('');
    setApiSearchText('');
  };

  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: { setToggleValue: toggleIsRecognitionFlowModalOpen },
  } = useToggle();

  const handleSuffixAdornmentClickCb = () => {
    trackMainFeedActionBarEvent(
      MAIN_FEED_ACTION_BAR_EVENTS.ACTION_BAR_DROPDOWN_SEARCH_CLEAR_CLICKED,
    );
  };

  const handleCelebrateTeammateClick = useCallback(() => {
    if (pageParticipationTreatment === TreatmentTypes.ON) {
      history.push(
        `${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`,
      );
    } else {
      toggleIsRecognitionFlowModalOpen();
    }
  }, [toggleIsRecognitionFlowModalOpen]);

  const handleFlowClick = useCallback(
    (flowId: string, occurrenceId?: string) => {
      if (pageParticipationTreatment === TreatmentTypes.ON) {
        history.push(
          `/flows/${flowId}/answer?redirectUrl=${location.pathname}`,
        );
      } else {
        if (flowId === CELEBRATE_TEAMMATE_FLOW_ID) {
          toggleIsRecognitionFlowModalOpen();
        } else {
          setParticipationFlow({
            participationFlowId: flowId,
            occurrenceId,
          });
        }
      }

      trackMainFeedActionBarEvent(
        MAIN_FEED_ACTION_BAR_EVENTS.PARTICIPATION_STARTED,
        { flowId },
      );
    },
    [
      history,
      setParticipationFlow,
      pageParticipationTreatment,
      trackMainFeedActionBarEvent,
      toggleIsRecognitionFlowModalOpen,
    ],
  );
  const handleFlowFeedClick = useCallback(
    (flowId: string) => {
      const getLinkURL = FLOWS_FEEDS.replace(':flowId', flowId);
      push(getLinkURL);
      trackMainFeedActionBarEvent(
        MAIN_FEED_ACTION_BAR_EVENTS.VIEW_FEED_CLICKED,
        { flowId },
      );
    },
    [push, trackMainFeedActionBarEvent],
  );

  const handleAddNewFlowClick = useCallback(() => {
    setCreateFlowModalOpen(true);
    trackMainFeedActionBarEvent(MAIN_FEED_ACTION_BAR_EVENTS.ADD_FLOW_CLICKED);
    trackTemplateGalleryShowEvent({
      show: TEMPLATE_GALLERY_EVENTS.TEMPLATE_GALLERY_SHOW,
      source: 'actionBar',
    });
  }, [setCreateFlowModalOpen, trackMainFeedActionBarEvent]);

  const onClickActionBarButtonCb = useCallback(() => {
    trackMainFeedActionBarEvent(
      MAIN_FEED_ACTION_BAR_EVENTS.DROPDOWN_ACTION_BAR_OPENED,
    );
  }, [trackMainFeedActionBarEvent]);

  const closeModalCb = useCallback(() => {
    trackMainFeedActionBarEvent(
      MAIN_FEED_ACTION_BAR_EVENTS.ACTION_BAR_DROPDOWN_CLOSED,
    );
  }, [trackMainFeedActionBarEvent]);

  const onNeedHelpClick = useCallback(() => {
    trackMainFeedActionBarEvent(MAIN_FEED_ACTION_BAR_EVENTS.NEED_HELP_CLICKED);
  }, [trackMainFeedActionBarEvent]);

  return {
    models: {
      isLoading,
      isSuccess,
      isError,
      todos,
      shortcuts,
      feeds,
      todoNotification,
      profileData,
      searchText,
      isRecognitionFlowModalOpen,
      openActionBarOnLoad,
    },
    operations: {
      closeModalCb,
      clearSearchText,
      handleFlowClick,
      onNeedHelpClick,
      handleCelebrateTeammateClick,
      handleFlowFeedClick,
      handleAddNewFlowClick,
      handleSearchTextChange,
      onClickActionBarButtonCb,
      handleSuffixAdornmentClickCb,
      toggleIsRecognitionFlowModalOpen,
    },
  };
};

export default useActionBarControllerLogic;
